import React, {useState} from 'react';
import {InputGroup} from "react-bootstrap";

function InputPassword({onChange, password, ...rest}) {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <InputGroup>
            <input type={showPassword ? 'text' : 'password'} id="password" value={password}
                   onChange={onChange}
                   className="" maxLength={25} required {...rest} />
            <a href="javascript:void(0)" className={'show_password text-link'}
               onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Hide' : 'Show'}
            </a>
        </InputGroup>
    );
}

InputPassword.propTypes = {};
InputPassword.defaultProps = {};

export default InputPassword;
