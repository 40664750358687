import React, {useEffect, useState} from 'react';
import {
    approveListing,
    banListing,
    deleteListing,
    getAllListingsAdmin,
    purgeListing,
    resolveListing,
    restoreListingAdmin
} from "../../utils/api";
import alertify from "alertifyjs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AdminListingsTable from "./AdminListingsTable";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";


const AdminListings = (props) => {
    const [listings, setListings] = useState([]);
    const [search, setSearch] = useState(() => "")
    const [isLoading, setIsLoading] = useState(() => false);

    useEffect(() => {
        let s;
        if (localStorage) {
            s = localStorage.getItem("adminListingSearch");
        }
        if (!s) {
            s = "";
        }
        setSearch(s);
        loadListings(s);

    }, []);

    function loadListings(s) {
        setIsLoading(true);
        let searchString = s;
        if (!searchString) {
            searchString = search;
        }
        localStorage.setItem("adminListingSearch", searchString);

        getAllListingsAdmin(searchString).then(resp => {
            resp.json().then(data => {
                setListings(data.listings);
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doDeleteListing(listingId) {
        setIsLoading(true);
        deleteListing(listingId).then(response => {
            if (response.ok) {
                alertify.success("Listing deleted");
            } else {
                alertify.error("Error deleting listing");
            }
            loadListings();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doResolveListing(listingId) {
        setIsLoading(true);
        resolveListing(listingId).then(response => {
            if (response.ok) {
                alertify.success("Listing resolved");
            } else {
                alertify.error("Error resolved listing");
            }
            loadListings();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doBanListing(listingId) {
        setIsLoading(true);
        banListing(listingId).then(response => {
            if (response.ok) {
                alertify.success("Listing banned");
            } else {
                alertify.error("Error banning listing");
            }
            loadListings();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doRestoreListing(listingId, isRestore) {
        setIsLoading(true);
        restoreListingAdmin(listingId).then(response => {
            if (response.ok) {
                if (isRestore) {
                    alertify.success("Listing restored!");
                } else {
                    alertify.success("Listing renewed");
                }
            } else {
                alertify.error("Error renewing listing");
            }
            loadListings();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doApproveListing(listingId) {
        setIsLoading(true);
        approveListing(listingId).then(response => {
            if (response.ok) {
                alertify.success("Listing approved");
            } else {
                alertify.error("Error approving listing");
            }
            loadListings();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doPurgeListing(listingId) {
        if (window.confirm("Remove this listing and all of its data. This is not reversible!")) {
            setIsLoading(true);
            purgeListing(listingId).then(response => {
                if (response.ok) {
                    alertify.success("Listing purged");
                } else {
                    alertify.error("Error purging listing");
                }
                loadListings();
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }

    let eventKey = "activeListings";
    if (localStorage && localStorage.getItem("adminListingPage")) {
        eventKey = localStorage.getItem("adminListingPage");
    }
    return (
        <>
            <Row style={{marginTop: "40px"}}>
                <Col md={4}>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="Search Listings"
                            disabled={isLoading} value={search} id={"listingSearchInput"} onChange={(e) => {
                            setSearch(e.target.value.trim())
                        }} onKeyUp={event => {
                            if (event.key === 'Enter') {
                                loadListings();
                            }
                        }}
                        />
                        <Button disabled={isLoading} onClick={() => {
                            loadListings()
                        }} variant="primary">Search</Button>
                    </InputGroup>
                </Col>
            </Row>
            <br/>
            <h3>Listings</h3>
            <Tabs
                defaultActiveKey={eventKey}
                transition={false}
                onSelect={(k) => localStorage.setItem("adminListingPage", k)}
            >
                <Tab eventKey="pendingListings" title="Pending">
                    <AdminListingsTable statuses={["PENDING"]} listings={listings} isLoading={isLoading}
                                        restore={doRestoreListing}
                                        approve={doApproveListing} delete={doDeleteListing} ban={doBanListing}
                                        resolve={doResolveListing}/>
                </Tab>
                <Tab eventKey="activeListings" title="Active">
                    <AdminListingsTable statuses={["ACTIVE", "EXPIRING_SOON"]} listings={listings} isLoading={isLoading}
                                        isSysAdmin={props.isSysAdmin}
                                        restore={doRestoreListing}
                                        approve={doApproveListing} delete={doDeleteListing} ban={doBanListing}
                                        resolve={doResolveListing}/>
                </Tab>
                <Tab eventKey="endedListings" title="Ended">
                    <AdminListingsTable statuses={["EXPIRED", "RESOLVED"]} listings={listings} isLoading={isLoading}
                                        restore={doRestoreListing}
                                        approve={doApproveListing} delete={doDeleteListing} ban={doBanListing}
                                        resolve={doResolveListing}/>
                </Tab>
                <Tab eventKey="removedListings" title="Removed">
                    <AdminListingsTable statuses={["BANNED", "DELETED"]} listings={listings} isLoading={isLoading}
                                        restore={doRestoreListing} isSysAdmin={props.isSysAdmin}
                                        approve={doApproveListing} delete={doDeleteListing} ban={doBanListing}
                                        resolve={doResolveListing} purge={doPurgeListing}/>
                </Tab>
            </Tabs>
        </>
    )
}

export default AdminListings;
