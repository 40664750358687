import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";


const AdminListingsTable = (props) => {
    return (
        <>
            <Table striped bordered size="sm">
                <thead style={{textAlign: "center"}}>
                <tr>
                    <th>Title</th>
                    <th>Creator</th>
                    <th>Category</th>
                    <th>Renewed/Created</th>
                    <th>Incident</th>
                    <th>Views</th>
                    <th>Tips</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody style={{textAlign: "center"}}>
                {props.listings && (props.listings.map(p => {
                    if (props.statuses.indexOf(p.data.status) === -1) {
                        return <></>
                    }
                    return (
                        <tr>
                            <td><a href={"/listing/view/" + p.data.id}> {p.data.title} </a></td>
                            <td>{p.data.userId.email}<br/>{p.data.userId.displayName}</td>
                            <td>{p.data.categoryId.category} {p.data.subCategory ? (" - " + p.data.subCategory) : ""}</td>
                            <td>
                                <small>
                                    {p.data.status === "PENDING" && (
                                        "Created: " + p.data.formattedCreatedDate
                                    )}
                                    {p.data.status !== "PENDING" && (
                                        <>

                                            {!!p.data.formattedRenewedDate && (
                                                <>

                                                    Renewed {p.data.formattedRenewedDate}
                                                    <br/>
                                                    Created {p.data.formattedCreatedDate}
                                                </>
                                            )}
                                            {!p.data.formattedRenewedDate && (
                                                <>
                                                    Created {p.data.formattedCreatedDate}
                                                </>

                                            )}
                                        </>

                                    )}
                                </small>

                            </td>
                            <td>{p.data.formattedDate}</td>
                            <td>{p.data.views}</td>
                            <td>{p.data.totalTips}</td>
                            <td>
                                {p.data.status === "EXPIRING_SOON" && (
                                    <b>{"Expires in " + p.data.expires.timeRemaining + " " + p.data.expires.value}</b>
                                )}
                                {p.data.status === "ACTIVE" && (
                                    "Expires in " + p.data.expires.timeRemaining + " " + p.data.expires.value
                                )}
                                {p.data.status === "PENDING" && (
                                    "Pending"
                                )}
                                {p.data.status === "EXPIRED" && (
                                    "Expired"
                                )}
                                {p.data.status === "BANNED" && (
                                    "Banned"
                                )}
                                {p.data.status === "DELETED" && (
                                    "Deleted"
                                )}
                                {p.data.status === "RESOLVED" && (
                                    "Resolved"
                                )}
                            </td>
                            <td>
                                {p.data.status === "PENDING" && (
                                    <>
                                        <Button disabled={props.isLoading} onClick={() => {
                                            props.approve(p.data.id)
                                        }} variant="success">Approve</Button>
                                        <span>&nbsp;&nbsp;</span>
                                    </>
                                )}
                                {(p.data.status === "BANNED" || p.data.status === "DELETED") && (
                                    <>
                                        <Button disabled={props.isLoading} onClick={() => {
                                            props.restore(p.data.id, true)
                                        }} variant="success">Restore</Button>
                                        <span>&nbsp;&nbsp;</span>
                                        {props.isSysAdmin && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.purge(p.data.id)
                                                }} variant="dark">Purge</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}
                                    </>
                                )}
                                {(p.data.status !== "BANNED" && p.data.status !== "DELETED") && (
                                    <>
                                        {p.data.status !== "PENDING" && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.restore(p.data.id, false)
                                                }} variant="info">Renew</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}

                                        {p.data.status !== "RESOLVED" && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.resolve(p.data.id)
                                                }}>Mark As Resolved</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}


                                        <Button disabled={props.isLoading} onClick={() => {
                                            props.delete(p.data.id)
                                        }} variant="warning">Remove</Button>
                                        <span>&nbsp;&nbsp;</span>

                                    </>
                                )}
                            </td>
                        </tr>
                    )
                }))}
                </tbody>
            </Table>
        </>
    )
}

export default AdminListingsTable;
