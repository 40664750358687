import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Modal, Nav, Navbar, Offcanvas, Row} from "react-bootstrap";
import {checkNotifications, deleteNotification, getUserInfo, logout, markAsRead, updateAccount} from '../../utils/api';
import 'react-confirm-alert/src/react-confirm-alert.css';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import {createPhoneNumber} from "../../utils/helpers";

alertify.set('notifier', 'position', 'top-right');


const Navigator = (props) => {

    const [notifications, setNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [showAccount, setShowAccount] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showNotifications, setShowNotifications] = useState([]);
    const [userInfo, setUserInfo] = useState(null);
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleAccountClose = () => {
        setShowAccount(false);
        let unread = unreadNotifications;
        for (let i = 0; i < notifications.length; i++) {
            if (notifications[i].onReadDelete !== true && notifications[i].read === false) {
                markAsRead(notifications[i]._id);
                notifications[i].read = true;
                unread--;
            }
        }
        setUnreadNotifications(unread);
    };
    const handleShowAccount = () => setShowAccount(true);

    let navItems;
    let baseItems = [
        {
            href: "/postListing",
            html: (<Button variant={"danger"}>Report a Crime</Button>)
        },
        {
            href: "/",
            html: "Home",
        },
    ]
    if (props.isAdmin) {
        const adminNavItems = [
            {
                href: "/admin",
                html: "Admin",
            },
        ];
        baseItems = adminNavItems.concat(baseItems);
    }
    if (props.loggedIn) {
        const loggedInNavItems = [
            {
                href: "/listings",
                html: "My Listings",
            },
            {
                href: "#",
                html: (
                    <button style={{color: "rgba(0,0,0,.7)"}} type="button" className="noButton" key="Account"
                            onClick={handleShowAccount}>
                        Notifications {unreadNotifications === 0 ? null :
                        <span className="unreadNotifications px-1 fs-6">{unreadNotifications}</span>}
                    </button>
                ),
            },
            {
                href: "#",
                html: (
                    <button style={{color: "rgba(0,0,0,.7)"}} type="button" className="noButton" key="MyAccount"
                            onClick={() => {
                                setShowEdit(true);
                                setShowAccount(false);
                            }}>
                        My Account
                    </button>
                ),
            },
            {
                href: "#",
                html: (
                    <button style={{color: "rgba(0,0,0,.7)"}} type="button" className="noButton" key="SignOut"
                            onClick={() => confirmSignOff()}>
                        Sign Out
                    </button>
                ),
            },
        ]
        navItems = baseItems.concat(loggedInNavItems)
    } else {
        const loggedOutNavItems = [
            {
                href: "/login",
                html: "Login / Create Account",
            },
        ]
        navItems = baseItems.concat(loggedOutNavItems)
    }

    async function checkNotify() {
        const response = await checkNotifications();
        if (response.status === 200) {
            const notificationData = await response.json();
            setNotifications(notificationData);
            let array = [];
            let unread = 0;
            for (let i = 0; i < notificationData.length; i++) {
                array.push(true);
                if (!notificationData[i].read) {
                    unread++;
                }
            }
            setUnreadNotifications(unread);
            setShowNotifications(array);
        } else {
            setNotifications([]);
        }
    }

    useEffect(async () => {
        if (props.loggedIn) {
            const response = await getUserInfo();
            if (response.ok) {
                const userData = await response.json();
                setUserInfo(userData);
                setEmail(userData.email);
                if (userData.phoneNumber) {
                    setPhoneNumber(userData.phoneNumber);
                }
                await checkNotify();
            }
        }

        const interval = setInterval(async () => {
            await checkNotify();
        }, 300000);

        return () => clearInterval(interval);
    }, [props.loggedIn])

    function signOff() {
        handleAccountClose();
    }

    async function confirmSignOff() {
        await logout();
        localStorage.removeItem("user");
        window.location.replace('/');
    }

    async function applyChanges(e) {
        e.preventDefault();
        if (password !== confirmPassword) {
            alertify.error("Passwords do not match!");
            return;
        }
        const response = await updateAccount(email, password, phoneNumber);
        if (response.ok) {
            const userData = await response.json();
            if (userData.email === userInfo.email && userData.phoneNumber === userInfo.phoneNumber) {

            } else {
                setUserInfo(userData);
                alertify.success("Account updated successfully!");
                setShowEdit(false);
            }
        } else {
            if (response.status === 400) {
                await response.json();
                alertify.error("Oops! There was an error updating your account. Please refresh and try again.");
            } else {
                alertify.error("Oops! There was an error updating your account. Please try again at a later time.");
            }
        }
    }

    async function removeNotification(id, index) {
        const response = await deleteNotification(id);
        if (response.ok) {
            let array = showNotifications;
            array[index] = false;
            setShowNotifications(array);
            await checkNotify();
        } else if (response.status === 400) {
            const notify = await response.json();
            console.log(notify.errorMessage)
        }
    }

    return (
        <>
            <Container fluid>
                <Navbar bg="light" expand="lg" sticky="top">
                    <Container>
                        <Navbar.Brand href="#home"><a className="navbarLogo" href={'/'}><img width={'250px'}
                                                                                             alt="helpID.me Logo"/></a></Navbar.Brand>
                        <Navbar.Toggle aria-controls="helpid-navbar-nav"/>
                        <Navbar.Collapse id="helpid-navbar-nav" className="justify-content-end">
                            {navItems.map((item, index) => {
                                return (
                                    <Nav.Link href={item.href} style={{color: "rgba(0,0,0,.7)"}} key={index}>
                                        {item.html}
                                    </Nav.Link>
                                )
                            })}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Offcanvas show={showAccount} onHide={handleAccountClose} backdrop={true} enforceFocus={false}
                           scroll={true} placement="end" className="accountOffCanvas">
                    <Offcanvas.Header closeButton style={{borderBottom: "2px solid var(--cyan)"}}>
                        <Offcanvas.Title>Notifications</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-0">
                        <div className="py-2 ps-3">
                            {notifications.length === 0 ?
                                <div className="text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path
                                            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                    </svg>
                                    <p className="fs-5 pt-2">You have no notifications.</p>
                                </div> :
                                notifications.map((notification, index) => {
                                    if (notification.postId && notification.postId._id) {
                                        return (
                                            <div key={index + "notify"}>
                                                <Alert variant={notification.read ? "secondary" : "info"}
                                                       className="pb-0"
                                                       show={showNotifications[index]}
                                                       onClose={() => removeNotification(notification._id, index)}
                                                       dismissible>
                                                    <p style={{cursor: "pointer"}} className="text-dark"
                                                       onClick={() => {
                                                           window.location.replace(`/listing/view/${notification.postId._id}`);
                                                       }}>
                                                        <b>{notification.postId.title}:</b> New tip submitted!
                                                    </p>
                                                </Alert>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div>
                                                <Alert variant={notification.read ? "secondary" : "info"}
                                                       className="pb-0"
                                                       show={showNotifications[index]} onClose={() => {
                                                    removeNotification(notification._id, index)
                                                }} dismissible>
                                                    <p className="text-dark">{notification.message}</p>
                                                </Alert>
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <Modal size="lg" centered show={showEdit} onHide={() => {
                    setShowEdit(false);
                    setEmail(userInfo ? userInfo.email : "");
                    setPhoneNumber(userInfo ? userInfo.phoneNumber ? userInfo.phoneNumber : "" : "");
                    setPassword("");
                    setConfirmPassword("");
                }}>
                    <Modal.Header closeButton className="modalForm" style={{borderBottom: "2px solid var(--cyan)"}}>
                        <Modal.Title>{userInfo ? userInfo.email + "'s" : ""} Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modalForm py-0">
                        <Container fluid>
                            <Row>
                                <Col xs={12} as="form" className="editAccountForm" onSubmit={(e) => applyChanges(e)}>
                                    <Row className="my-3 d-flex">
                                        <div className="d-flex">
                                            <label htmlFor="phoneNumber">Update Phone #:</label>
                                            <input type="tel" id="phoneNumber" className="text-center modalFormInput"
                                                   value={phoneNumber}
                                                   minLength={12} maxLength={12}
                                                   onChange={(e) => setPhoneNumber(createPhoneNumber(e.target.value, phoneNumber))}></input>
                                        </div>
                                    </Row>
                                    <Row className="my-3 d-flex">
                                        <div className="d-flex">
                                            <label htmlFor="email">Update Email:</label>
                                            <input type="email" id="email" className="text-center modalFormInput"
                                                   minLength={10} maxLength={40}
                                                   onChange={(e) => setEmail(e.target.value)}></input>
                                        </div>
                                    </Row>
                                    <Row className="my-3 d-flex">
                                        <div className="d-flex">
                                            <label htmlFor="password">Update Password:</label>
                                            <input type="password" id="password" className="text-center modalFormInput"
                                                   minLength={6}
                                                   maxLength={25}
                                                   onChange={(e) => setPassword(e.target.value)}></input>
                                        </div>
                                    </Row>
                                    <Row className="my-3 d-flex">
                                        <div className="d-flex">
                                            <label htmlFor="passwordConfirm">Confirm Password:</label>
                                            <input type="password" id="passwordConfirm"
                                                   className="text-center modalFormInput" minLength={6}
                                                   maxLength={25}
                                                   onChange={(e) => setConfirmPassword(e.target.value)}></input>
                                        </div>
                                    </Row>
                                    <Row className="d-flex justify-content-center align-items-center my-3 pb-1">
                                        <button type="submit" className="button" style={{maxWidth: "75%"}}>Apply Changes
                                        </button>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
            </Container>
        </>
    );
}

export default Navigator;
