import React, {useEffect, useState} from 'react';
import {addCategory, deleteCategory, deleteSubCategory, getCategories} from "../../utils/api";
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import alertify from "alertifyjs";
import Row from "react-bootstrap/Row";
import SubCategoryAdd from "./categories/SubCategoryAdd";

const AdminCategories = (props) => {

    const [categories, setCategories] = useState(() => [])
    const [isSaving, setIsSaving] = useState(false)
    const [newCategory, setNewCategory] = useState("")

    function loadCategories() {
        setIsSaving(true);
        getCategories().then(categoriesResponse => {
            setCategories(categoriesResponse);
        }).finally(() => {
            setIsSaving(false);
        });
    }

    function doAddCategory() {
        if (!newCategory) {
            return;
        }
        setIsSaving(true);
        addCategory(newCategory).then(response => {
            if (response.ok) {
                alertify.success("Category Added");
                setNewCategory("");
                loadCategories();
            } else {
                alertify.error("Error adding category");
            }
        }).finally(() => {
            setIsSaving(false);
        });
    }


    function doDeleteCategory(categoryId) {
        setIsSaving(true);
        deleteCategory(categoryId).then(response => {
            if (response.ok) {
                alertify.success("Category Deleted");
                loadCategories();
            } else {
                alertify.error("Error deleting category");
            }
        }).finally(() => {
            setIsSaving(false);
        });
    }

    function doDeleteSubCategory(categoryId, categoryTitle) {
        setIsSaving(true);
        deleteSubCategory(categoryId, categoryTitle).then(response => {
            if (response.ok) {
                alertify.success("Sub Category Deleted");
                loadCategories();
            } else {
                alertify.error("Error deleting sub category");
            }
        }).finally(() => {
            setIsSaving(false);
        });
    }


    useEffect(() => {
        loadCategories();
    }, []);

    return (
        <div style={{marginTop: "50px"}}>
            <Row>
                <Col md={4} style={{padding: 0, marginBottom: "10px"}}>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="Add Category"
                            disabled={isSaving} value={newCategory} id={"newCategoryInput"} onChange={(e) => {
                            setNewCategory(e.target.value)
                        }}
                        />
                        <Button disabled={isSaving} onClick={() => {
                            doAddCategory()
                        }} variant="primary">Add</Button>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <ListGroup as="ol">
                    {categories.map(c => (
                        <ListGroup.Item
                            as="li"
                            className="justify-content-between align-items-start"
                        >
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">
                                    {c.category} <Button style={{lineHeight: "15px", marginLeft: "8px"}}
                                                         disabled={isSaving}
                                                         variant={"outline-danger"}
                                                         onClick={() => doDeleteCategory(c._id)}>X</Button>
                                </div>
                                {c.subCategories.map(sc => (
                                    <>
                                        <div style={{marginTop: "15px"}}>
                                            <span style={{paddingLeft: "20px"}}>{sc}</span>
                                            {' '}
                                            <Button style={{lineHeight: "15px", marginLeft: "8px"}} disabled={isSaving}
                                                    variant={"outline-danger"}
                                                    onClick={() => doDeleteSubCategory(c._id, sc)}>X</Button>
                                        </div>
                                    </>
                                ))}
                                <SubCategoryAdd isSaving={isSaving} setSaving={setIsSaving} categoryObj={c}/>
                            </div>
                        </ListGroup.Item>

                    ))}
                </ListGroup>
            </Row>
        </div>
    )
}

export default AdminCategories;
