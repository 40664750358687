import React from "react";
import VerifiedUserShield from "./VerifiedUserShield";


const UserDisplay = (props) => {
    const user = props.user;
    if(user.verifiedName) {

        return (
            <>
            <VerifiedUserShield name={user.verifiedName}/>
            </>
        )
    } else {
        return (
            <span>{user.displayName}</span>
        );

    }
}

export default UserDisplay;
