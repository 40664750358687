import React, {useEffect, useState,} from 'react';
import {ButtonGroup, Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import {useParams} from 'react-router-dom';
import {createTip, deleteTip, getOneListing} from '../utils/api';
import {LazyLoadImage} from "react-lazy-load-image-component";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import HelpIDMap from "./HelpIDMap";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton
} from "react-share";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getListingCenterForMap, getThumbNailPath, isVideo} from "../utils/helpers";
import UserDisplay from "../components/UserDisplay";

alertify.set('notifier', 'position', 'top-right');


function IndividualListing(props) {
    const params = useParams();
    const listingId = params.listingId;
    const [listingInfo, setListingInfo] = useState(null);
    const [showTipCreation, setShowTipCreation] = useState(false);
    const [title, setTitle] = useState("");
    const [subject, setSubject] = useState("");
    const [anonymous, setAnonymous] = useState(false);
    const [tipFormError, setTipFormError] = useState(null);
    const [selectedMediaUrl, setSelectedMediaUrl] = useState(null);
    const [selectedMediaIsImage, setSelectedMediaIsImage] = useState(true);

    const setMediaUrl = (index, item) => {
        if (!item || !item.key || item.key.trim() === "") {
            setSelectedMediaIsImage(true);
            setSelectedMediaUrl(null);
        }
        const url = `${process.env.REACT_APP_CDN}${item.key}`;
        setSelectedMediaIsImage(!isVideo(url));
        setSelectedMediaUrl(url.trim());
    };

    function doDeleteTip(tipId) {
        deleteTip(tipId).then(() => {
            document.getElementById("" + tipId).remove();
            alertify.success("Tip Removed");
        }).catch((err) => {
        });

    }

    useEffect(() => {
        if (listingInfo && listingInfo.title) {
            document.title = listingInfo.title;
        }
    }, [listingInfo]);


    useEffect(async () => {
        if (!params.listingId) {
            alertify.error("We were unable to load this listing.", "2");
            return;
        }
        const response = await getOneListing(params.listingId);
        if (response.ok && response.status === 200) {
            const listingData = await response.json();
            setListingInfo(listingData.data);
        } else if (response.status === 400) {
            await response.json();
            alertify.error("We were unable to load this listing.", "2");

        } else if (response.status === 404) {
            alertify.error("We were unable to find this listing.", "2");


        } else {
            alertify.error("We were unable to load this listing.", "2");

        }
    }, []);


    function renderThumbNails() {
        let thumbs = [];
        if (listingInfo.allMedia && listingInfo.allMedia.length) {
            listingInfo.allMedia.map((media, idx) => {
                if (isVideo(media)) {
                    thumbs.push(((
                        <div id={"media" + idx}>
                            <img
                                key={media}
                                src={`${process.env.REACT_APP_CDN}${getThumbNailPath(media)}`}
                                className="carouselThumbs"
                            />
                        </div>
                    )))
                } else {
                    thumbs.push(
                        <div>
                            <LazyLoadImage
                                id={"lazyImg" + idx}
                                key={idx}
                                alt={"https://" + window.location.hostname + media}
                                src={`${process.env.REACT_APP_CDN}${media}`}
                                className="carouselThumbs"
                            />
                        </div>
                    )
                }
            })
        }
        return thumbs;
    }

    function thumbScrollChange(index, item) {
        // credit: https://stackoverflow.com/questions/51403628/react-scroll-component-to-view-inside-overflow-hidden-element
        const divToScrollTo = document.getElementById(index);
        if (divToScrollTo) {
            divToScrollTo.scrollIntoView({block: "end", inline: "center"});
        }
    }

    async function sendTip(e) {
        e.preventDefault();
        setTipFormError(null);
        let myForm = document.getElementById("tipForm");
        let formData = new FormData(myForm);
        formData.append("id", listingInfo._id);

        const response = await createTip(formData);
        if (response.ok) {
            setShowTipCreation(false);
            await response.json();
            alertify.success("Thank you! Your tip has been added successfully", "2", () => {
                window.location.reload()
            });
        } else if (response.status === 400) {
            const error = await response.json();
            alertify.error("Oops. Error submitting tip.")
            if (error.errorMessage === "Your file is too large. The maximum size for a file is 0.5MB") {
                setTipFormError("Please check media files. The max size is 15mb.");
            } else {
                setTipFormError("Please confirm fields are correct and try again at at later time");
            }

        } else {
            await response.json();
            alertify.error("Oops. Error submitting tip. Please try again at a later time.")
        }
    }

    const userOwnsListing = (props.loggedIn && props.user && props.user.id && listingInfo && listingInfo.userId && listingInfo.userId.id && props.user.id === listingInfo.userId.id)
    const mapCenter = getListingCenterForMap(listingInfo);

    const currentUrl = window.location.href;

    return (
        <Container fluid="xxl" className="p-0">
            <Container fluid className="individualContainer">
                <div style={{marginLeft: "100px", marginRight: "100px"}}>
                    <>
                        {listingInfo && !listingInfo.isActive && (
                            <Row>
                                <Col>
                                    <Row>
                                        {listingInfo.isPending && (
                                            <Alert variant="warning" style={{textAlign: "center"}}>
                                                This listing is pending admin approval.
                                            </Alert>
                                        )}
                                        {listingInfo.isBanned && (
                                            <Alert variant="danger" style={{textAlign: "center"}}>
                                                This listing has been removed and is only available to admins.
                                            </Alert>
                                        )}
                                        {listingInfo.status === "RESOLVED" && (
                                            <Alert variant="info" style={{textAlign: "center"}}>
                                                This listing has been resolved.
                                            </Alert>
                                        )}
                                        {listingInfo.status === "EXPIRED" && (
                                            <Alert variant="info" style={{textAlign: "center"}}>
                                                This listing has expired.
                                            </Alert>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            {listingInfo && listingInfo.allMedia && listingInfo.allMedia.length ?
                                <Carousel autoPlay={false} showArrows={true} infiniteLoop={true}
                                          className="imageCarousel p-0" renderThumbs={renderThumbNails}
                                          onClickItem={setMediaUrl}
                                          onChange={(index, item) => thumbScrollChange(index, item)}>
                                    {
                                        listingInfo.allMedia.map(media => {
                                            if (isVideo(media)) {
                                                return <div className="carouselVideoDiv" key={media}>
                                                    <video width="400" height="300" controls
                                                           onClick={e => e.preventDefault()}>
                                                        <source src={`${process.env.REACT_APP_CDN}${media}`}
                                                                type="video/mp4"/>
                                                    </video>
                                                </div>
                                            } else {
                                                return <div key={media}>
                                                    <LazyLoadImage
                                                        alt={"https://" + window.location.hostname + media}
                                                        src={`${process.env.REACT_APP_CDN}${media}`}
                                                        className="carouselImages"
                                                    />
                                                </div>
                                            }
                                        })
                                    }
                                </Carousel> :
                                <div className="d-flex justify-content-center">
                                    <Spinner animation="border" role="status"
                                             style={{width: "75px", height: "75px", marginTop: "10vh"}}>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>}
                        </Row>
                    </>
                </div>
                <Row>
                    {
                        props.loggedIn && userOwnsListing && listingInfo && (
                            <div className={'d-flex justify-content-end align-items-center mt-3'}>
                                <button type="submit" className="button" onClick={() => {
                                    window.location = "/listing/edit/" + listingId
                                }}>
                                    Edit Listing
                                </button>
                            </div>)
                    }
                    <div className="d-flex justify-content-center align-items-center">
                        <h1 className="fs-1 pt-3 px-3 text-center individualTitle">{listingInfo ? listingInfo.title : null}</h1>
                    </div>
                    <div className="borderB_grey px-4">
                        <div className="pt-3 d-flex justify-content-between">
                            <p className="ps-lg-4 pe-2">
                                {listingInfo ? listingInfo.subCategory ? listingInfo.categoryId.category + " - " + listingInfo.subCategory : `${listingInfo.categoryId.category}` : null}
                            </p>
                            <p className="pe-lg-4 text-end individualInfoDivs">
                                {listingInfo && (
                                    <>
                                        Created by: <UserDisplay user={listingInfo.userId}/>
                                    </>
                                )}
                            </p>
                        </div>
                        <div className="pt-3 d-flex justify-content-between">
                            <p className="ps-lg-4 pe-2">{listingInfo ? "Date of Incident: " + listingInfo.formattedDate : null}</p>
                            {(listingInfo && listingInfo.title && listingInfo.isActive) && (
                                <p className="pe-lg-4 text-end">
                                    <DropdownButton className={"no-after"} variant={"link"} id="dropdown-basic-button"
                                                    title="Share Listing" as={ButtonGroup}>
                                        <Dropdown.Item eventKey="1">
                                            <FacebookShareButton quote={listingInfo.title} url={currentUrl}
                                                                 hashtag={"#helpid"}>
                                                <FacebookIcon borderRadius={100} size={20}/>
                                                <small style={{paddingLeft: "5px"}}>Facebook</small>
                                            </FacebookShareButton>
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="2">
                                            <TwitterShareButton title={listingInfo.title} hashtags={["helpid"]}
                                                                url={currentUrl}>
                                                <TwitterIcon borderRadius={100} size={20}/>
                                                <small style={{paddingLeft: "5px"}}>Twitter</small>
                                            </TwitterShareButton>
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="3">
                                            <EmailShareButton url={currentUrl}
                                                              subject={"HelpId.me: " + listingInfo.title}
                                                              body={"Hey do you know anything? Check out my listing on HelpID.me"}
                                                              separator={"\n\n"}>
                                                <EmailIcon borderRadius={100} size={20}/>
                                                <small style={{paddingLeft: "5px"}}>Email</small>
                                            </EmailShareButton>
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center borderB_grey">
                        <p className="fs-5 pt-3 px-3">{listingInfo ? listingInfo.summary : null}</p>
                    </div>
                </Row>
                {!!mapCenter && (
                    <Row>
                        <HelpIDMap isStatic={true} id={"viewListing"} center={mapCenter} zoom={16}
                                   centerMarkerCircleLocation={mapCenter}
                                   createCenterMarkerCircle={true} createCenterMarker={true}/>
                    </Row>
                )}
                <Row className="pb-2" style={{borderTop: '2px solid var(--cyan)', marginTop: "45px"}}>
                    <Col xs={12} className='d-flex justify-content-end align-items-center'>
                        {props.loggedIn && !userOwnsListing && (
                            <>
                                <p className="pt-3 pe-2"> Know something?</p>
                                <button className="button mt-2" type="button"
                                        onClick={() => setShowTipCreation(true)}>Submit a Tip
                                </button>
                            </>
                        )}
                    </Col>
                </Row>
                <Row className="px-md-4 px-xs-3 d-flex justify-content-center">
                    {listingInfo ? listingInfo.tips.length > 0 ? listingInfo.tips.map(tip =>
                                <Col md={6} className="my-2" key={tip._id} id={tip._id}>
                                    <div className="showTips ms-md-3">
                                        <div className="d-flex justify-content-between">
                                            <p className="fs-4 px-3 borderB_grey">{tip.title}</p>
                                            <p className="px-3 pt-1 borderB_grey">{tip.createdAt}</p>
                                        </div>
                                        <p className=" ps-3">{tip.subject}</p>
                                        {tip.image ?
                                            <div className="d-flex justify-content-center mx-3">
                                                <img src={`${process.env.REACT_APP_CDN}${tip.image}`}
                                                     className="tipImage mb-3" alt={tip.title}></img>
                                            </div>
                                            : <p></p>}
                                        {tip.canDelete && (
                                            <div className="d-flex justify-content-between">
                                                <Button variant="link" onClick={() => {
                                                    doDeleteTip(tip._id)
                                                }}><FontAwesomeIcon icon={faTrashCan}/></Button>
                                                <p className="text-end px-3 mb-0 pb-1 tipUsername"><UserDisplay
                                                    user={tip.userId}/></p>
                                            </div>
                                        )}
                                        {!tip.canDelete && (
                                            <div className="d-flex justify-content-end">
                                                <p className="text-end px-3 mb-0 pb-1 tipUsername"><UserDisplay
                                                    user={tip.userId}/></p>
                                            </div>
                                        )}
                                    </div>
                                </Col>)
                            : <div className="d-flex justify-content-center align-items-center" key="None Found"
                                   style={{minHeight: "30vh"}}>
                                <Container fluid>
                                    <Row>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                             fill="currentColor"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path
                                                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                        </svg>
                                    </Row>
                                    <Row>
                                        <p className="text-center fs-1 mt-4">
                                            {props.loggedIn && !userOwnsListing ? "No tips yet. Be the first!" : "No tips yet."}
                                        </p>
                                    </Row>
                                </Container>
                            </div>
                        : null}
                </Row>
                <Row style={{marginTop: "50px"}}></Row>
            </Container>
            <Modal size="lg" centered show={showTipCreation} onHide={() => {
                setShowTipCreation(false);
            }}>
                <Modal.Header closeButton className="modalForm" style={{borderBottom: "5px solid var(--cyan)"}}>
                    <Modal.Title>Submit A Tip</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modalForm py-0">
                    <Container fluid>
                        <Row>
                            <p className="my-2 borderB_cyan">This is not a comment section. Please only submit tips
                                to help identify the individual(s) involved.
                            </p>
                        </Row>
                        <Row>
                            <Col xs={12} as="form" className="editAccountForm" id="tipForm" onSubmit={(e) => sendTip(e)}
                                 onChange={() => setTipFormError(null)}>
                                <Row className="my-3 d-flex">
                                    <div className="d-flex">
                                        <label htmlFor="title">Title:</label>
                                        <input type="text" id="title" name="title"
                                               className="text-center modalFormInput" minLength={4}
                                               maxLength={50} valid required
                                               onChange={(e) => setTitle(e.target.value)}
                                               onBlur={(e) => {
                                                   if (e.target.value.length < 4) setTipFormError("Your title must be at least 4 characters long.");
                                               }}>
                                        </input>
                                    </div>
                                </Row>
                                <Row className="my-3 d-flex">
                                    <div className="d-flex">
                                        <textarea id="subject" name="subject" value={subject}
                                                  style={{width: "100%"}} className="px-2"
                                                  minLength={12} maxLength={1000} rows="8"
                                                  onChange={(e) => setSubject(e.target.value)} required
                                                  onBlur={(e) => {
                                                      if (e.target.value.length < 12) setTipFormError("Your subject must be at least 12 characters long.");
                                                      if (e.target.value.length > 1000) setTipFormError("Your subject can not exceed 1000 characters long.");
                                                  }}></textarea>
                                    </div>
                                </Row>
                                <Row className="my-3 d-flex">
                                    <div className="d-flex">
                                        <label htmlFor="image">Image Upload:</label>
                                        <input type="file" id="image" name="image" accept=".jpg, .png, .jpeg"
                                               className="modalFormInput"
                                        ></input>
                                    </div>
                                </Row>
                                <Row className="my-3 d-flex">
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="anonymous" className="">Would you like to remain
                                            Anonymous?:</label>
                                        <div className="form-check form-switch ms-auto" style={{border: "none"}}>
                                            <input type="checkbox" id="anonymous" name="anonymous" value={anonymous}
                                                   className="form-check-input px-3 fs-4"
                                                   onChange={(e) => setAnonymous(!anonymous)}></input>
                                        </div>
                                    </div>
                                </Row>
                                <Row className="d-flex justify-content-center align-items-center my-3 pb-1">
                                    {tipFormError ? <p className="text-danger text-center">{tipFormError}</p> : null}
                                    <button type="submit" className="button" style={{maxWidth: "75%"}}>Submit Tip
                                    </button>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
            {selectedMediaUrl && (

                <div id="imageViewerModal" className="W3modal">
                    <span className="W3close" onClick={() => setSelectedMediaUrl(null)}>&times;</span>
                    {selectedMediaIsImage && (
                        <img className="W3modal-content" src={selectedMediaUrl}/>
                    )}
                    {!selectedMediaIsImage && (
                        <video controls className="W3modal-content">
                            <source src={selectedMediaUrl} type="video/mp4"/>
                        </video>
                    )}
                </div>
            )}

        </Container>
    );
}

export default IndividualListing;
