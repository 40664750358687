import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import {deleteTip, searchTips} from "../../utils/api";
import alertify from "alertifyjs";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AdminTips = (props) => {

    const [search, setSearch] = useState(() => "")
    const [tips, setTips] = useState(() => []);
    const [isLoading, setIsLoading] = useState(() => false);

    useEffect(() => {
        let s;
        if (localStorage) {
            s = localStorage.getItem("adminTipSearch");
        }
        if (s) {
            setSearch(s);
            loadTips(s);
        }
    }, []);

    function doDeleteTip(tipId) {
        setIsLoading(true);
        deleteTip(tipId).then(response => {
            if (response.ok) {
                alertify.success("Tip Removed!");
            } else {
                alertify.error("Error deleting tip!");
            }
            loadTips();
        }).finally(() => {
            setIsLoading(false);
        });

    }

    function loadTips(s) {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        let searchString = s;
        if (!searchString) {
            searchString = search;
        }
        localStorage.setItem("adminTipSearch", searchString);
        searchTips(searchString).then(tipResponse => {
            if (!tipResponse.ok) {
                alertify.error("Error getting tips");
                return;
            }
            tipResponse.json().then(tipDatas => {
                setTips(tipDatas)
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function getSubject(str) {
        if (!str || !str.trim()) {
            return "";
        }
        if (str.length < 30) {
            return str;
        }
        return str.substring(0, 27) + "...";
    }

    return (
        <>
            <Row style={{marginTop: "40px"}}>

                <Col md={4}>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="Search Tips"
                            disabled={isLoading} value={search} id={"tipSearchInput"} onChange={(e) => {
                            setSearch(e.target.value.trim())
                        }} onKeyUp={event => {
                            if (event.key === 'Enter') {
                                loadTips();
                            }
                        }}
                        />
                        <Button disabled={isLoading} onClick={() => {
                            loadTips()
                        }} variant="primary">Search</Button>
                    </InputGroup>
                </Col>
            </Row>
            <Row>

                <Col md={12}>
                    <br/>
                    <Table striped bordered size="sm">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th>Content</th>
                            <th>Created</th>
                            <th>User</th>
                            <th>Listing</th>
                            <th>Anon?</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tips.map(t => (
                            <tr>
                                <td>{t.title}</td>
                                <td>{getSubject(t.subject)}</td>
                                <td>{t.createdAtFormatted}</td>
                                <td>{t.userId.email}<br/>{t.userId.displayName}</td>
                                <td><a href={"/listing/view/" + t.postId._id}>{t.postId.title} </a></td>
                                <td style={{textAlign: "center"}}>{t.anonymous ? <>&#x2713;</> : ""}</td>
                                <td><Button variant={"danger"} onClick={() => {
                                    doDeleteTip(t.id)
                                }}></Button></td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default AdminTips;
