import React, {useState,} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {resetUserPassword} from '../utils/api';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css'
import Alert from "react-bootstrap/Alert";

alertify.set('notifier', 'position', 'top-right');


function ResetPassword(props) {
    const [email, setEmail] = useState("");
    const [hasReset, setHasReset] = useState(false);

    function doReset() {
        resetUserPassword(email).finally(() => {
            setHasReset(true);
            setEmail("");
        });

    }

    return (
        <Container fluid>
            {hasReset && (
                <Row>
                    <Alert variant="info" style={{textAlign: "center"}}>
                        An email has been sent with a link to reset your password.
                    </Alert>
                </Row>
            )}
            {!hasReset && (
                <Row className="d-flex justify-content-center align-items-center mt-5">
                    <Col lg={3} md={6} xs={12} className="loginDiv">
                        <h2 className="text-center my-4">Reset Password</h2>
                            <div className="my-4" style={{marginTop: "10px"}}>
                                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                       className="" maxLength={40} required placeholder={"Enter your email"}></input>
                            </div>
                            <div className="d-flex justify-content-center align-items-center my-4">
                                <button type="submit" onClick={doReset} className="button">Send Reset Link</button>
                            </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default ResetPassword;
