import React, {useEffect, useState} from 'react';
import {
    banUser,
    makeUserAdmin,
    removeUserAdmin,
    removeUserVerification,
    searchUsers,
    unbanUser,
    verifyUser
} from "../../utils/api";
import alertify from "alertifyjs";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Accordion, Modal} from "react-bootstrap";
import VerifiedUserShield from "../../components/VerifiedUserShield";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AdminUsersTable from "./AdminUsersTable";

const AdminUsers = (props) => {

    const [search, setSearch] = useState(() => "")
    const [users, setUsers] = useState(() => []);
    const [totalUsersCount, setTotalUserCounts] = useState(0);
    const [emailConfirmedUsersCount, setEmailConfirmedUserCounts] = useState(0);
    const [bannedUsersCount, setBannedUsersCount] = useState(0);
    const [adminUsersCount, setAdminUsersCount] = useState(0);
    const [loggedInUsersCount, setLoggedInUsersCount] = useState(0);
    const [isLoading, setIsLoading] = useState(() => false);
    const [verifiedUserData, setVerifiedUserData] = useState(null);
    const [newVerifiedName, setNewVerifiedName] = useState(null);

    useEffect(() => {
        let s = "";
        if (localStorage) {
            s = localStorage.getItem("adminUserSearch") || "";
        }
        if (s) {
            setSearch(s);
        }
        loadUsers(s);
    }, []);

    function doBanUser(userId) {
        let reason = prompt("Enter ban reason (optional)");
        if (reason === null) {
            return;
        }
        setIsLoading(true);
        banUser(userId, reason).then(response => {
            if (response.ok) {
                alertify.success("User banned");
            } else {
                alertify.error("Error banning user");
            }
            loadUsers();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doUnbanUser(userId) {
        setIsLoading(true);
        unbanUser(userId).then(response => {
            if (response.ok) {
                alertify.success("User ban removed");
            } else {
                alertify.error("Error removing user ban");
            }
            loadUsers();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doMakeAdmin(userId) {
        setIsLoading(true);
        makeUserAdmin(userId).then(response => {
            if (response.ok) {
                alertify.success("User made admin!");
            } else {
                alertify.error("Error making user admin");
            }
            loadUsers();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doRemoveVerification(userId) {
        setIsLoading(true);
        removeUserVerification(userId).then(response => {
            if (response.ok) {
                alertify.success("User verification removed");
            } else {
                alertify.error("Error removing user verification");
            }
            loadUsers();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doVerifyUser() {
        setIsLoading(true);
        verifyUser(verifiedUserData.id, newVerifiedName).then(response => {
            if (response.ok) {
                alertify.success("Verified User!");
            } else {
                alertify.error("Error verifying user");
            }
            loadUsers();
        }).finally(() => {
            setVerifiedUserData(null);
            setNewVerifiedName(null);
            setIsLoading(false);
        });
    }

    function doRemoveAdmin(userId) {
        setIsLoading(true);
        removeUserAdmin(userId).then(response => {
            if (response.ok) {
                alertify.success("Remove admin role from user!");
            } else {
                alertify.error("Error removing admin role from user");
            }
            loadUsers();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function loadUsers(s) {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        let searchString = s;
        if (!searchString) {
            searchString = search;
        }
        localStorage.setItem("adminUserSearch", searchString);
        searchUsers(searchString).then(userResponse => {
            if (!userResponse.ok) {
                alertify.error("Error getting users");
                return;
            }
            userResponse.json().then(userDatas => {
                const stats = userDatas["stats"]
                setBannedUsersCount(stats["bannedTotalUsers"]);
                setAdminUsersCount(stats["adminTotalUsers"]);
                setTotalUserCounts(stats["allTotalUsers"]);
                setLoggedInUsersCount(stats["loggedInTotalUsers"]);
                setEmailConfirmedUserCounts(stats["emailConfirmedTotalUsers"]);
                let userDatasCombine = [];
                for (let key in userDatas) {
                    if (key !== "stats" && userDatas.hasOwnProperty(key)) {
                        let userData = userDatas[key].user;
                        userData.possibleActions = userDatas[key].possibleActions;
                        userData.hasListings = userDatas[key].hasListings;
                        userData.createdAtFormatted = userDatas[key].createdAtFormatted;
                        userData.emailConfirmedAtFormatted = userDatas[key].emailConfirmedAtFormatted;
                        userData.bannedAtFormatted = userDatas[key].bannedAtFormatted;
                        userData.lastLoginAtFormatted = userDatas[key].lastLoginAtFormatted;
                        userDatasCombine.push(userData);
                    }
                }
                setUsers(userDatasCombine);
            });
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function startVerify(user) {
        setNewVerifiedName(user.verifiedName);
        setVerifiedUserData(user);
    }

    let eventKey = "activeUsers";
    if (localStorage && localStorage.getItem("adminUsersPage")) {
        eventKey = localStorage.getItem("adminUsersPage");
    }

    return (
        <>
            {verifiedUserData && (
                <Modal show style={{textAlign: "center"}}>
                    <Modal.Header>
                        <Modal.Title>Verify {verifiedUserData.email}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Enter the name to be displayed for this user ({verifiedUserData.displayName}):</p>
                        <input type="text" id="title" name="title" className="modalFormInput"
                               maxLength={50} required
                               value={newVerifiedName}
                               onChange={(e) => setNewVerifiedName(e.target.value)}
                        >
                        </input>
                        {newVerifiedName && (
                            <p style={{marginTop: "20px"}}>
                                <VerifiedUserShield name={newVerifiedName}/>
                            </p>
                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setNewVerifiedName("");
                            setVerifiedUserData(null);
                        }}>Cancel</Button>
                        <Button variant="primary" disabled={!(newVerifiedName && newVerifiedName.trim())}
                                onClick={doVerifyUser}>Verify User</Button>
                    </Modal.Footer>
                </Modal>
            )}
            <Row style={{marginTop: "40px"}}>
                <Col md={12}>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Total User Stats</Accordion.Header>
                            <Accordion.Body>
                                <p>Total Users: {totalUsersCount}</p>
                                <p>Confirmed Users: {emailConfirmedUsersCount}</p>
                                <p>Has Logged In: {loggedInUsersCount}</p>
                                <p>Banned Users: {bannedUsersCount}</p>
                                <p>Admins: {adminUsersCount}</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Col>
            </Row>
            <Row style={{marginTop: "40px"}}>
                <Col md={4}>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder="Search Users"
                            disabled={isLoading} value={search} id={"userSearchInput"} onChange={(e) => {
                            setSearch(e.target.value.trim())
                        }} onKeyUp={event => {
                            if (event.key === 'Enter') {
                                loadUsers();
                            }
                        }}
                        />
                        <Button disabled={isLoading} onClick={() => {
                            loadUsers()
                        }} variant="primary">Search</Button>
                    </InputGroup>
                </Col>
            </Row>

            <Row style={{marginTop: "20px", marginBottom: "50px"}}>
                <Col>
                    <Tabs
                        defaultActiveKey={eventKey}
                        transition={false}
                        onSelect={(k) => localStorage.setItem("adminUsersPage", k)}
                    >
                        <Tab eventKey="createdListing" title="Created Listing">
                            <AdminUsersTable filter={u => u.hasListings} users={users} isLoading={isLoading}
                                             doBanUser={doBanUser}
                                             startVerify={startVerify} doRemoveVerification={doRemoveVerification}
                                             doMakeAdmin={doMakeAdmin} doUnbanUser={doUnbanUser}
                                             doRemoveAdmin={doRemoveAdmin}
                            />
                        </Tab>
                        <Tab eventKey="activeUsers" title="Active">
                            <AdminUsersTable filter={u => !!u.emailConfirmedAt && !u.bannedAt} users={users}
                                             isLoading={isLoading}
                                             doBanUser={doBanUser}
                                             startVerify={startVerify} doRemoveVerification={doRemoveVerification}
                                             doMakeAdmin={doMakeAdmin} doUnbanUser={doUnbanUser}
                                             doRemoveAdmin={doRemoveAdmin}
                            />
                        </Tab>
                        <Tab eventKey="unconfirmedUsers" title="Unconfirmed">
                            <AdminUsersTable filter={u => !u.emailConfirmedAt && !u.bannedAt} users={users}
                                             isLoading={isLoading}
                                             doBanUser={doBanUser}
                                             startVerify={startVerify} doRemoveVerification={doRemoveVerification}
                                             doMakeAdmin={doMakeAdmin} doUnbanUser={doUnbanUser}
                                             doRemoveAdmin={doRemoveAdmin}
                            />
                        </Tab>
                        <Tab eventKey="banned" title="Banned">
                            <AdminUsersTable filter={u => !!u.bannedAt} users={users} isLoading={isLoading}
                                             doBanUser={doBanUser}
                                             startVerify={startVerify} doRemoveVerification={doRemoveVerification}
                                             doMakeAdmin={doMakeAdmin} doUnbanUser={doUnbanUser}
                                             doRemoveAdmin={doRemoveAdmin}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    )
}

export default AdminUsers;
