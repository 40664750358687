import React, {useEffect} from 'react';
import {CloseButton, Col, Row} from "react-bootstrap";
import SelectCategory from "./SelectCategory";
import SelectSubCategory from "./SelectSubCategory";
import Loading from "../Loading";
import ListingFiles from "../../components/Listing/ListingFiles";
import HelpIDMap from "../../pages/HelpIDMap";
import Form from 'react-bootstrap/Form';
import {getCenterForMap, getListingCenterForMap} from "../../utils/helpers";

function ListingForm(props) {
    const {errorMessages, listingData, isEdit} = props;
    const [formValues, setFormValues] = React.useState({});
    const [hasFiles, setHasFiles] = React.useState(false);
    const [subCategories, setSubCategories] = React.useState(null);
    const [initCenterListingMap, setInitCenterListingMap] = React.useState();
    const [selectedMapCenter, setSelectedMapCenter] = React.useState(null);

    useEffect(() => {
        if (!isEdit) {
            getCenterForMap().then(centerVals => {
                if (centerVals && centerVals.length === 2) {
                    const initCenterLoaded = {"lat": Number(centerVals[1]), "lng": Number(centerVals[0])}
                    setInitCenterListingMap(initCenterLoaded);
                }
            });
        }
    }, [listingData]);

    useEffect(() => {
        if (isEdit && listingData && listingData.id) {
            const mapCenter = getListingCenterForMap(listingData);
            setInitCenterListingMap(mapCenter);
            setSelectedMapCenter(mapCenter);
            const {
                categoryId,
                location,
                summary,
                title,
                subCategory,
                date,
                address,
                formattedDate,
                mapLat,
                mapLng,
                allMedia,
            } = listingData;
            setFormValues({
                categoryId: categoryId ? categoryId._id : null,
                location,
                fileUrls: allMedia || [],
                summary,
                title,
                subCategory,
                date,
                formattedDate,
                mapLat,
                mapLng,
                address
            });
        }
    }, [listingData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        let myForm = document.getElementById("listingForm");
        let formData = new FormData(myForm);
        formData.append('categoryId', document.getElementById("categoryId").value);
        formValues.removeFileUrls && formData.append('removeImages', JSON.stringify(formValues.removeFileUrls));
        if(formValues.fileUrls) {
            formData.append('allMedia', JSON.stringify(formValues.fileUrls));
        }
        document.getElementById("subCategory") && formData.append('subCategory', document.getElementById("subCategory").value)
        window.formDataLast = formData;
        props.onSubmit(formData);
    };

    const handleSetFormValue = (name, value) => {
        setFormValues((old) => {
            return {
                ...old,
                [name]: value
            }
        })
    };

    const handleFilesSet = (e) => {
        let hasFile = false;
        const files = e.target.files;
        if (files) {
            // Loop through files
            for (let i = 0; i < files.length; i++) {
                let file = files.item(i);
                if (file.size > 0) {
                    hasFile = true;
                }
            }
        }
        setHasFiles(hasFile);
    };

    const clearFiles = () => {
        const filesInput = document.getElementById("listingFiles");
        filesInput.value = null;
        setHasFiles(false);
    };

    const changeFilesOrder = (currentPos, newPos) => {
        const newFileUrls = [...formValues.fileUrls]
        let element = newFileUrls[currentPos];
        newFileUrls.splice(currentPos, 1);
        newFileUrls.splice(newPos, 0, element);
        handleSetFormValue("fileUrls", newFileUrls)
    }

    const updateFormMapLatLng = (currentLatLng) => {
        setSelectedMapCenter(currentLatLng)
        document.getElementById("mapLatValue").value = currentLatLng.lat;
        document.getElementById("mapLngValue").value = currentLatLng.lng;
    }

    const {
        title,
        summary,
        categoryId,
        subCategory,
        fileUrls = [],
        removeFileUrls = [],
        date,
        // mapLat,
        // mapLng,
    } = formValues;

    let date_val;
    if (date) {
        date_val = new Date(date.split('T')[0] + 'T00:00:00.000Z').toISOString().split('T')[0]
    }
    let errorItems = null;
    if (errorMessages && errorMessages.length > 0) {
        errorItems = errorMessages.map((message) =>
            <p className="text-danger text-center" style={{marginBottom: "2px"}}>{message}</p>
        );
    }
    const mapCenter = selectedMapCenter ? selectedMapCenter : initCenterListingMap;
    return (
        <Row>
            <Col xs={12} as="form" className="editAccountForm" id="listingForm" onSubmit={() => {
            }}>
                {errorItems}
                <Row className="my-3 d-flex">
                    <div className="d-flex">
                        <label htmlFor="title">Title:</label>
                        <input type="text" id="title" name="title" className="modalFormInput" minLength={4}
                               maxLength={50} required
                               value={title}
                               onChange={(e) => handleSetFormValue('title', e.target.value)}
                        >
                        </input>
                    </div>
                </Row>
                <Row className="my-3 d-flex">
                    <div className="d-flex">
                        <label htmlFor="title"> Date of Incident:</label>
                        <input type="date" id="date" name="date" className="modalFormInput"
                               required
                               max={new Date().toISOString().split('T')[0]}
                               value={date_val}
                               onChange={(e) => handleSetFormValue('date', e.target.value)}
                        />
                    </div>
                </Row>

                <Row className="my-3 d-flex">
                    <div className="d-flex">
                        <label htmlFor="title">Category:</label>
                        <SelectCategory
                            id={'categoryId'}
                            className="modalFormInput"
                            categoryId={categoryId}
                            onChange={(cateId, cateItem) => {
                                handleSetFormValue('categoryId', cateId)
                                setSubCategories(cateItem.subCategories)
                            }}

                        />
                    </div>
                </Row>

                {subCategories && (
                    <Row className="my-3 d-flex">
                        <div className="d-flex">
                            <label htmlFor="title">Sub Category:</label>
                            <SelectSubCategory
                                subCategories={subCategories}
                                id={'subCategory'}
                                className="modalFormInput"
                                subCateId={subCategory}
                                onChange={(value) => handleSetFormValue('subCategory', value)}
                            />
                        </div>
                    </Row>
                )}

                <Row className="my-3 d-flex">
                    <div className="d-flex">
                <textarea id="subject" name="summary" value={summary} placeholder={"Describe the events..."}
                          style={{width: "100%"}} className="px-2"
                          maxLength={2000} rows="4"
                          onChange={(e) => handleSetFormValue('summary', e.target.value)} required
                />
                    </div>
                </Row>

                {!isEdit && (
                    <h6 style={{marginTop: "40px"}}>Click the map to place the marker on the location of the incident.
                        Only
                        the <b>approximate</b> location will be shown to other users.</h6>
                )}
                <Row className="my-3 d-flex">
                    <div style={{display: "none"}}>
                        <input id={"mapLatValue"} type={"hidden"} name={"mapLat"}/>
                        <input id={"mapLngValue"} type={"hidden"} name={"mapLng"}/>
                    </div>
                    <div style={{minHeight: "300px"}}>
                        {mapCenter && (
                            <HelpIDMap isStatic={isEdit} showAddressSearchInput={true} zoom={16} id={"createListing"}
                                       createCenterMarkerCircle={isEdit} createCenterMarker={true} addressSearchZoom={19}
                                       center={mapCenter}
                                       onClick={(e, map) => {
                                           if (isEdit) {
                                               return
                                           }
                                           const newPos = e.latLng
                                           const newPosObj = {"lat": newPos.lat(), "lng": newPos.lng()}
                                           if (map.lastMarker) {
                                               map.lastMarker.setPosition(newPosObj)
                                           }
                                           updateFormMapLatLng(newPosObj)
                                       }}

                                       onMarkerDragend={(e, marker) => {
                                           if (isEdit) {
                                               return
                                           }
                                           const newPos = e.latLng
                                           const newPosObj = {"lat": newPos.lat(), "lng": newPos.lng()}
                                           updateFormMapLatLng(newPosObj)
                                       }}
                                       addressSearchCallback={(map, newCenter) => {
                                           if (isEdit) {
                                               return
                                           }
                                           if (map.lastMarker) {
                                               map.lastMarker.setPosition(newCenter);
                                           }
                                       }}
                            />
                        )}
                    </div>
                </Row>

                <Row className="my-3" style={{marginTop:"20px"}}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                        <Form.Label>
                            Add up to five video or image files <small>(max 15MB
                            each)</small>. Use <b>ctrl</b> to select multiple image files.
                        </Form.Label>
                        {hasFiles && (
                            <CloseButton style={{float: "right"}} onClick={clearFiles}/>
                        )}
                        <Form.Control name={"files"} type="file" id={"listingFiles"} multiple
                                      onChange={handleFilesSet}/>
                    </Form.Group>
                    {
                        fileUrls && <ListingFiles
                            changeOrder={changeFilesOrder}
                            fileUrls={fileUrls}
                            removeFileUrls={removeFileUrls}
                            onRemove={(removeUrl) => handleSetFormValue('removeFileUrls', [...removeFileUrls, removeUrl])}/>
                    }
                </Row>
                <Row>
                    {errorItems}
                </Row>

                <Row className="d-flex justify-content-center align-items-center my-3 pb-1">
                    <button type="button" onClick={handleSubmit} className="button" style={{maxWidth: "75%"}}>
                        {
                            props.loading ? <Loading/> : isEdit ? 'Update Listing' : 'Create Listing'
                        }
                    </button>

                </Row>
            </Col>
        </Row>
    );
}

ListingForm.propTypes = {};
ListingForm.defaultProps = {};

export default ListingForm;
