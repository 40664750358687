import React from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VerifiedUserShield from "../../components/VerifiedUserShield";

function getUserStatus(userData) {
    if (userData.bannedAt) {
        let r = "User banned at " + userData.bannedAtFormatted;
        if (userData.banReason) {
            return (r + " for " + userData.banReason);
        }
        return r;
    }
    if (userData.sysAdmin) {
        return "System Admin";
    }
    if (userData.admin) {
        return "Admin";
    }
    return "Active";
}

function getUserStatusColor(userData) {
    if (userData.bannedAt) {
        return "red";
    }
    if (userData.sysAdmin) {
        return "black";
    }
    if (userData.admin) {
        return "green";
    }
    return "blue";
}

const AdminUsersTable = (props) => {
    const usersToUse = [];
    if(props.users && props.users.length) {
        for (let i = 0; i < props.users.length; i++) {
            if (props.filter(props.users[i])) {
                usersToUse.push(props.users[i]);
            }
        }
    }

    return (
        <>
            <Row>
                <Col md={12}>
                    <br/>
                    <Table striped bordered size="sm">
                        <thead>
                        <tr>
                            <th>Email</th>
                            <th>First</th>
                            <th>Last</th>
                            <th>Display</th>
                            <th>Phone</th>
                            <th>Dates</th>
                            <th>IPs</th>
                            <th>Status</th>
                            <th>Listings</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {usersToUse.map(u => (
                            <tr>
                                <td>{u.email}</td>
                                <td>{u.firstName}</td>
                                <td>{u.lastName}</td>
                                <td>
                                    {u.displayName}
                                    {u.verifiedName && (
                                        <>
                                            <br></br>
                                            <VerifiedUserShield name={u.verifiedName}/>
                                        </>
                                    )}
                                </td>
                                <td>{u.phoneNumber}</td>
                                <td>Created: {u.createdAtFormatted} <br/>
                                    Email Confirmed: {u.emailConfirmedAtFormatted} <br/>
                                    Last Login: {u.lastLoginAtFormatted}
                                </td>
                                <td>Created: {u.createdIp}<br/>
                                    Last Login: {u.lastLoginIp}
                                </td>
                                <td style={{color: getUserStatusColor(u)}}>{getUserStatus(u)}</td>
                                <td>
                                    {u.hasListings && (
                                        <a href={"/listings?uId=" + u.id}>View Listings</a>
                                    )}
                                </td>
                                <td>
                                    <>
                                        {u.possibleActions.indexOf("BAN") >= 0 && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.doBanUser(u.id)
                                                }} variant="danger">Ban</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}
                                        {u.possibleActions.indexOf("VERIFY_USER") >= 0 && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.startVerify(u);
                                                }} variant="secondary">Verify</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}
                                        {u.possibleActions.indexOf("REMOVE_VERIFY_USER") >= 0 && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.doRemoveVerification(u.id)
                                                }
                                                } variant="secondary">Remove Verification</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}
                                        {u.possibleActions.indexOf("MAKE_ADMIN") >= 0 && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.doMakeAdmin(u.id)
                                                }} variant="success">Make Admin</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}
                                        {u.possibleActions.indexOf("UNBAN") >= 0 && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.doUnbanUser(u.id)
                                                }} variant="primary">Remove Ban</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}
                                        {u.possibleActions.indexOf("REMOVE_ADMIN") >= 0 && (
                                            <>
                                                <Button disabled={props.isLoading} onClick={() => {
                                                    props.doRemoveAdmin(u.id)
                                                }} variant="warning">Remove Admin</Button>
                                                <span>&nbsp;&nbsp;</span>
                                            </>
                                        )}
                                    </>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default AdminUsersTable;
