import React, {useState} from 'react';
import {addSubCategory} from "../../../utils/api";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import alertify from "alertifyjs";

const SubCategoryAdd = (props) => {

    const {isSaving, setSaving, categoryObj} = props;
    const categoryId = categoryObj._id;
    const [newSubCategory, setNewSubCategory] = useState("");

    function doAddSubCategory() {
        if (!newSubCategory) {
            return;
        }
        setSaving(true);
        addSubCategory(categoryObj._id, newSubCategory).then(response => {
            if (response.ok) {
                alertify.success("Sub Category Added");
                categoryObj.subCategories = categoryObj.subCategories.concat([newSubCategory]);
                setNewSubCategory("");
            } else {
                alertify.error("Error adding sub category");
            }
        }).finally(() => {
            setSaving(false);
        });
    }

    return (
        <div style={{marginTop: "20px", marginBottom: "20px", marginLeft: "20px", maxWidth: "450px"}}>
            <InputGroup>
                <Form.Control
                    type="text"
                    placeholder={"Add '" + categoryObj.category + "' sub category"}
                    disabled={isSaving} value={newSubCategory} id={"newSubCategoryInput" + categoryId}
                    onChange={(e) => {
                        setNewSubCategory(e.target.value)
                    }}
                />
                <Button disabled={isSaving} onClick={() => {
                    doAddSubCategory()
                }} variant="primary">
                    Add
                </Button>
            </InputGroup>
        </div>
    )
}

export default SubCategoryAdd;
