import React, {useMemo, useState} from 'react';
import './style.css';
import './w3Modal.css';
import {BrowserRouter, Route, Routes,} from 'react-router-dom';
import Navigator from "./components/Navbar";
import Login from "./pages/Login";
import Signup from './pages/Signup';
import Main from "./pages/Main";
import IndividualListing from './pages/IndividualListing';
import NotFound from './pages/NotFound';
import HelpIDMap from "./pages/HelpIDMap";
import Admin from "./pages/admin/Admin";
import Protected from "./Protected";
import MyListings from "./pages/MyListings";
import Cookies from 'js-cookie';
import ResetPassword from "./pages/ResetPassword";
import SetNewUserPassword from "./pages/SetNewUserPassword";
import PostListing from "./pages/PostListing";

function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSysAdmin, setIsSysAdmin] = useState(false);
    const [user, setUser] = useState();

    useMemo(() => {
        let loginSuccess = false;
        try {
            const userDataStr = Cookies.get("userData");
            if (userDataStr) {
                const foundUser = JSON.parse(userDataStr);
                if (foundUser) {
                    setUser(foundUser);
                    setLoggedIn(true);
                    setIsAdmin(foundUser.admin);
                    setIsSysAdmin(foundUser.sysAdmin);
                    loginSuccess = true;
                }
            }
        } catch (e) {
            console.log("Login error");
            console.log(e);
        } finally {
            if (!loginSuccess) {
                setUser(undefined);
                setLoggedIn(false);
                setIsAdmin(false);
                setIsSysAdmin(false);
            }
        }
    }, []);

    return (
        <BrowserRouter as='main'>
            <Navigator loggedIn={loggedIn} isAdmin={isAdmin} isSysAdmin={isSysAdmin} user={user}/>
            <Routes>
                <Route path='/' element={<Main loggedIn={loggedIn}/>}/>
                <Route path='/listings'
                       element={<Protected allowed={loggedIn} loginNextUrl={"/listings"}><MyListings
                           loggedIn={loggedIn} isAdmin={isAdmin} isSysAdmin={isSysAdmin}
                           user={user}/></Protected>}/>
                <Route path='/login' element={<Login/>}/>
                <Route path='/loginActivated' element={<Login activated={true}/>}/>
                <Route path='/resetPassword' element={<ResetPassword/>}/>
                <Route path='/newPassword' element={<SetNewUserPassword/>}/>
                <Route path='/postListing'
                       element={<Protected allowed={true} loginNextUrl={"/postListing"}><PostListing isEdit={false}/></Protected>}/>
                <Route path='/mapTesting' element={<>
                    <HelpIDMap isStatic={true} id={"viewListing"} draggable={false} disableDefaultUI={true}
                               showZipInput={false} zoom={18} keyboardShortcuts={false}
                               createCenterMarkerCircle={true}/>
                    <HelpIDMap id={"test2"}/>
                </>}/>
                <Route path='/signup' element={<Signup/>}/>
                <Route path="/listing/edit/:listingId"
                       element={<Protected allowed={loggedIn} loginNextUrl={"/postListing"}><PostListing isEdit={true} /></Protected>}/>
                <Route path="/listing/view/:listingId"
                       element={<IndividualListing loggedIn={loggedIn} isAdmin={isAdmin} isSysAdmin={isSysAdmin}
                                                   user={user}/>}/>
                <Route path='/admin'
                       element={<Protected allowed={isAdmin} allowLoginRedirect={false}><Admin isSysAdmin={isSysAdmin}/></Protected>}/>
                <Route path="/notFound" element={<NotFound/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
            {/* <Footer /> */}
        </BrowserRouter>
    );
}

export default App;
