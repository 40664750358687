import NotFound from './pages/NotFound';
import Login from "./pages/Login";

const Protected = ({allowed, loginNextUrl, children}) => {
    if (!allowed) {
        if (loginNextUrl) {
            return <Login nextUrl={loginNextUrl}/>
        } else {
            return <NotFound/>;
        }
        // return <Navigate to="/" replace/>;
    }
    return children;
};
export default Protected;