import React from 'react';
import {Spinner} from "react-bootstrap";

function Loading({width, height}) {
    return (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"
                     style={{width: width + "px", height: height + "px", marginTop: "0"}}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

Loading.propTypes = {};
Loading.defaultProps = {};

export default Loading;
