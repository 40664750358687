export const createPhoneNumber = (value, pastValue) => {
    if (value.length === 3) {
        if (pastValue.charAt(pastValue.length - 1) !== "-") {
            return value + "-";
        }
    } else if (value.length === 7) {
        if (pastValue.charAt(pastValue.length - 1) !== "-") {
            return value + "-";
        }
    }
    return value;
};


function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

function saveInput() {
    console.log('Saving data');
}

const processChange = debounce(() => saveInput());


function getLocationLocalStorage() {
    if (window.localStorage && window.localStorage.lastCenterLat && window.localStorage.lastCenterLng) {
        return [window.localStorage.lastCenterLng, window.localStorage.lastCenterLat];
    }
    return undefined;
}

async function getLocationBrowser() {
    try {
        const pos = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        if (pos && pos.coords && pos.coords.longitude && pos.coords.latitude) {
            return [pos.coords.longitude, pos.coords.latitude];
        }
    } catch (e) {
        // user blocked location access
        console.log("navigator result error");
        console.log(e);
    }
    return undefined;
}


export async function getCenterForMap() {
    let location = await getLocationBrowser();
    if (!location) {
        location = getLocationLocalStorage();
    }
    if (!location) {
        // default SLC coordinates i guess lol
        return [-111.89482142259412, 40.76726846827848];
    }
    return location;
}

export function getListingCenterForMap(listingInfo) {
    let mapCenter;
    if (!!listingInfo) {
        if (listingInfo.mapLat && !isNaN(listingInfo.mapLat) && listingInfo.mapLng && !isNaN(listingInfo.mapLng)) {
            mapCenter = {"lat": Number(listingInfo.mapLat), "lng": Number(listingInfo.mapLng)}
        } else {
            let coords = listingInfo.coordinates;
            if (!coords && listingInfo.location) {
                coords = listingInfo.location.coordinates
            }
            if (coords && coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
                mapCenter = {"lat": Number(coords[1]), "lng": Number(coords[0])};
            }
        }
    }
    return mapCenter;
}


export function checkPasswords(p1, p2) {
    if (!p1) {
        return "Please enter a password.";
    }
    if(p1.length < 8) {
        return "Password must be at least 8 characters long";
    }
    if(p1 !== p2) {
        return "Passwords do not match";
    }
    return true;
}

const video_exts = [".mp4", ".MP4", ".avi", ".AVI", ".mpg", ".MPG", ".mov", ".MOV"];

export function getThumbNailPath(video) {
    const lastSlash = video.lastIndexOf("/");
    let thumbnailPath = video.substring(0, lastSlash) + "/THUMBNAIL_" + video.substring(lastSlash + 1);
    for (let i = 0; i < video_exts.length; i++) {
        thumbnailPath = thumbnailPath.replace(video_exts[i], ".jpg");
    }
    return thumbnailPath;
}

export function isVideo(video) {
    video = video.toLowerCase();
    for (let i = 0; i < video_exts.length; i++) {
        if(video.endsWith(video_exts[i].toLowerCase())) {
            return true;
        }
    }
    return false;
}