import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import './ListingFiles.css';
import {getThumbNailPath, isVideo} from "../../utils/helpers";


const FileThumbnail = ({swapImages, fileUrl, onRemove, imageIdx, hidden}) => {
    const [showDeleteBtn, setShowDeleteBtn] = React.useState(false)
    const imagePath = isVideo(fileUrl) ? getThumbNailPath(fileUrl) : fileUrl;
    const allowDrop = (e) => {
        e.preventDefault();
    };
    const drop = (e) => {
        e.preventDefault();
        const startId = e.dataTransfer.getData("imageIndexSrc");
        let endId = e.target.id.replace("imageDiv", "");
        if (isNaN(endId)) {
            endId = e.target.id.replace("imageImg", "");
        }
        if (isNaN(startId)) {
            throw new Error("Weird! StartID not num " + startId);
        }
        if (isNaN(endId)) {
            throw new Error("Weird! cannot get end id from " + e.target.id);
        }
        swapImages(startId, endId);
    };
    const dragStart = (e) => {
        e.dataTransfer.setData("imageIndexSrc", e.target.id.replace("imageImg", ""));
    };
    return (<div onMouseOver={() => setShowDeleteBtn(true)}
                 onMouseOut={() => setShowDeleteBtn(false)}
                 className={`post-file-container ${showDeleteBtn ? 'hover--on' : 'hover--off'}`}
                 style={{width: 125, display: hidden ? "none" : "block"}}
                 key={fileUrl}
                 onDrop={drop}
                 onDragOver={allowDrop}
                 id={"imageDiv" + imageIdx}
    >
        <Image draggable="true" onDragStart={dragStart}
               id={"imageImg" + imageIdx}
               style={{width: '100%'}}
               src={`${process.env.REACT_APP_CDN}${imagePath}`} thumbnail/>

        <Button className={'delete-button'} variant="danger" size={'sm'}
                onClick={() => onRemove(fileUrl)}
                style={{position: 'absolute', right: '50px', zIndex: 9999}}>
            X
        </Button>
    </div>)
}

function ListingFiles({changeOrder, fileUrls = [], removeFileUrls=[], onRemove}) {
    return (
        <div id={"non_draggable_images"}>
            <div className={'d-flex align-items-center'}>
                {
                    fileUrls
                        .map((fileUrl, index) => {
                            return <FileThumbnail hidden={removeFileUrls.includes(fileUrl)} swapImages={changeOrder} imageIdx={index} fileUrl={fileUrl} onRemove={onRemove}/>
                        })
                }
            </div>
        </div>
    );
}

ListingFiles.propTypes = {};
ListingFiles.defaultProps = {};

export default ListingFiles;
