const fetchWrapper = (path, options) => {
    return fetch(`${path}`, options)
}

export const renewLogin = () => {
    return fetchWrapper('/api/user/renew', {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    });
}

export const getCategories = async () => {
    const response = await fetchWrapper('/api/category/', {
        method: "GET"
    });
    const categories = await response.json();
    return categories
}

export const checkNotifications = () => {
    return fetchWrapper('/api/notification/', {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    });
}

export const markAsRead = (id) => {
    return fetchWrapper(`/api/notification/${id}`, {
        method: "PUT",
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const deleteNotification = (id) => {
    return fetchWrapper(`/api/notification/${id}`, {
        method: "DELETE",
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const getAllListings = (bounds, page = 1, sort = "Most Recent") => {

    let query;
    if(bounds) {
        query = `nelat=${encodeURIComponent(bounds[0])}&nelng=${encodeURIComponent(bounds[1])}&swlat=${encodeURIComponent(bounds[2])}&swlng=${encodeURIComponent(bounds[3])}&sort=${encodeURIComponent(sort)}&page=${page}`;
    } else {
        query = `sort=${encodeURIComponent(sort)}&page=${page}`;
    }
    return fetchWrapper("/api/listing/?" + query, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    });
};

export const getAllListingsAdmin = (search) => {
    let c = "&preventCache=" + new Date();
    if (!search || !search.trim()) {
        search = "";
    }
    const searchEncode = encodeURIComponent(search.trim())
    return fetchWrapper(`/api/listing/admin/listings/?s=${searchEncode}${c}`, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    });
}

export const getUserListings = (sort, uId) => {
    if (!sort) {
        sort = "Most Recent"
    }
    let qs = `sort=${sort}`
    if (uId) {
        qs += `&uId=${uId}`
    }

    console.log("Getting user listings")
    return fetchWrapper(`/api/listing/user/listings?${qs}`, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    });
}

export const getOneListing = (listingId) => {
    return fetchWrapper(`/api/listing/${listingId}`, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const login = (email, password) => {
    return fetchWrapper(`/api/user/login`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            password: password
        })
    })
}

export const signup = (personalWebsite, firstName, lastName, phoneNumber, email, password) => {
    return fetchWrapper(`/api/user/signup`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            personalWebsite: personalWebsite,
            firstName: firstName,
            lastName: lastName,
            password: password,
            phoneNumber: phoneNumber === "" ? undefined : phoneNumber
        })
    })
}

export const logout = () => {
    return fetchWrapper(`/api/user/logout`, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    });
}

export const getUserInfo = () => {
    return fetchWrapper(`/api/user/`, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const searchTips = (search) => {
    let c = "&preventCache=" + new Date();
    if (!search || !search.trim()) {
        search = "";
    }
    const searchEncode = encodeURIComponent(search.trim())
    return fetchWrapper(`/api/tip/search?s=${searchEncode}${c}`, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const searchUsers = (search) => {
    let c = "&preventCache=" + new Date();
    if (!search || !search.trim()) {
        search = "";
    }
    const searchEncode = encodeURIComponent(search.trim())
    return fetchWrapper(`/api/user/search?s=${searchEncode}${c}`, {
        method: "GET",
        headers: {
            'content-type': 'application/json'
        }
    })
}

export const makeUserAdmin = (userId) => {
    return fetchWrapper(`/api/user/makeAdmin`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId
        }),
    })
}

export const addCategory = (categoryTitle) => {
    return fetchWrapper(`/api/category/add`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            title: categoryTitle
        }),
    })
}


export const deleteCategory = (categoryId) => {
    return fetchWrapper(`/api/category/delete`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            categoryId: categoryId
        }),
    })
}

export const addSubCategory = (categoryId, categoryTitle) => {
    return fetchWrapper(`/api/category/addSub`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            categoryId: categoryId,
            title: categoryTitle
        }),
    })
}


export const deleteSubCategory = (categoryId, categoryTitle) => {
    return fetchWrapper(`/api/category/deleteSub`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            categoryId: categoryId,
            title: categoryTitle
        }),
    })
}


export const removeUserAdmin = (userId) => {
    return fetchWrapper(`/api/user/removeAdmin`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId
        }),
    })
}

export const banUser = (userId, reason) => {
    if (!reason) {
        reason = ""
    }
    return fetchWrapper(`/api/user/ban`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
            reason: reason,
        }),
    })
}

export const verifyUser = (userId, verifiedName) => {
    return fetchWrapper(`/api/user/verify`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
            verifiedName: verifiedName,
        }),
    })
}

export const unbanUser = (userId) => {
    return fetchWrapper(`/api/user/unban`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
        }),
    })
}

export const removeUserVerification = (userId) => {
    return fetchWrapper(`/api/user/verifyRemove`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
        }),
    })

}
export const updateAccount = (email, password, phoneNumber) => {
    return fetchWrapper(`/api/user/`, {
        method: "PUT",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            email: email === "" ? undefined : email,
            password: password === "" ? undefined : password,
            phoneNumber: phoneNumber === "" ? undefined : phoneNumber
        })
    })
}

export const createTip = (formData) => {
    return fetchWrapper('/api/tip/', {
        method: "POST",
        body: formData
    })
}

export const createListing = (formData) => {
    return fetchWrapper('/api/listing/', {
        method: "POST",
        body: formData
    })
}

export const updateListing = (id, formData) => {
    return fetchWrapper('/api/listing/user/' + id, {
        method: "PUT",
        body: formData
    })
}

export const getAPIGeolocation = () => {
    return fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBDx3YstM3mOBJKXSbehE_nDrf_KlXax24', {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    }).then(rs => {
        return rs.json()
    })
}


export const deleteListing = (listingId) => {
    return fetchWrapper(`/api/listing/delete/${listingId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}


export const deleteTip = (tipId) => {
    return fetchWrapper(`/api/tip/delete/${tipId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}


export const resolveListing = (listingId) => {
    return fetchWrapper(`/api/listing/resolve/${listingId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}


export const approveListing = (listingId) => {
    return fetchWrapper(`/api/listing/approve/${listingId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}

export const purgeListing = (listingId) => {
    return fetchWrapper(`/api/listing/purge/${listingId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}


export const banListing = (listingId) => {
    return fetchWrapper(`/api/listing/ban/${listingId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}

export const renewListing = (listingId) => {
    return fetchWrapper(`/api/listing/renew/${listingId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}

export const addressGeoCode = (addressText) => {
    return fetchWrapper(`/api/listing/address/lookup/`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            address: addressText,
        })
    })
}

export const restoreListingAdmin = (listingId) => {
    return fetchWrapper(`/api/listing/admin/renew/${listingId}`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
    })
}

export const resetUserPassword = (email) => {
    return fetchWrapper(`/api/user/resetPassword`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
        }),
    })
}


export const setUserNewPassword = (password, resetId) => {
    return fetchWrapper(`/api/user/setNewPassword`, {
        method: "POST",
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            password: password,
            resetId: resetId,
        }),
    })
}