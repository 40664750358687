import React from 'react';
import {getCategories} from "../../utils/api";

function SelectCategory({categoryId, onChange, ...rest}) {
    const [categories, setCategories] = React.useState([]);
    React.useEffect(() => {
        getCategories().then(categoriesRs => {
            setCategories(categoriesRs)
            if (categoryId) {
                const categoryItem = categoriesRs.find(category => category._id.toString() === categoryId)
                if (categoryItem) {
                    onChange(categoryId, categoryItem)
                }
            }
        });
    }, [])
    const handleOnChange = (e) => {
        const categoryItem = categories.find(category => category._id.toString() === e.target.value)
        onChange(e.target.value, categoryItem)
    }
    return <select
        value={categoryId}
        className="p-1"
        onChange={handleOnChange}
        style={{maxWidth: "100%"}}
        {...rest}
    >
        <option disabled={true} selected={true}>Select Category</option>
        {categories.map(category => (
            <option key={category._id} value={category._id}>{category.category}</option>
        ))}
    </select>
}

SelectCategory.propTypes = {};
SelectCategory.defaultProps = {};

export default SelectCategory;
