import React, {useEffect, useState,} from 'react';
import {Container, Row} from "react-bootstrap";
import {deleteListing, getUserListings, renewListing} from '../utils/api';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import queryString from "query-string";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faRotateRight, faTrash} from "@fortawesome/free-solid-svg-icons";

alertify.set('notifier', 'position', 'top-right');


const MyListings = (props) => {
    const [listings, setListings] = useState([]);
    const [isLoading, setIsLoading] = useState(() => false);

    useEffect(() => {
        loadListings();
    }, []);

    function loadListings() {
        getUserListings(undefined, queryString.parse(window.location.search).uId).then(resp => {
            resp.json().then(data => {
                console.log(data);
                setListings(data.listings);
            });
        });
    }

    function doDeleteListing(listingId) {
        if(!window.confirm("Delete this listing?")) {
            return;
        }
        setIsLoading(true);
        deleteListing(listingId).then(response => {
            if (response.ok) {
                alertify.success("Listing deleted");
            } else {
                alertify.error("Error deleting listing");
            }
            loadListings();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function doRenewListing(listingId) {
        setIsLoading(true);
        renewListing(listingId).then(response => {
            if (response.ok) {
                alertify.success("Listing renewed");
            } else {
                alertify.error("Error renewing listing");
            }
            loadListings();
        }).finally(() => {
            setIsLoading(false);
        });
    }

    let hasListings = (listings && listings.length > 0);
    return (
        <Container fluid className="" style={{minHeight: "80vh"}}>
            <Row className="justify-content-md-center" style={{marginTop: "40px"}}>
                {!hasListings && (
                    <h4 style={{textAlign: "center"}}>
                        No listings yet!
                    </h4>
                )}
                {hasListings && (
                    <Col md={10}>
                        <Table responsive="md" striped bordered size="sm">
                            <thead>
                            <tr>
                                <th>Title</th>
                                <th>Category</th>
                                <th>Created</th>
                                <th>Incident</th>
                                <th>Views</th>
                                <th>Tips</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {listings.map(p => (
                                <tr>
                                    <td><a href={"/listing/view/" + p.data.id}> {p.data.title} </a></td>
                                    <td>{p.data.categoryId.category} {p.data.subCategory ? (" - " + p.data.subCategory) : ""}</td>
                                    <td>{p.data.formattedCreatedDate} {p.data.formattedRenewedDate && (
                                        <>
                                            (Renewed at {p.data.formattedRenewedDate})
                                        </>
                                    )}
                                    </td>
                                    <td>{p.data.formattedDate}</td>
                                    <td>{p.data.views}</td>
                                    <td>{p.totalTips}</td>
                                    <td>
                                        {p.data.status === "EXPIRING_SOON" && (
                                            <b>{"Expires in " + p.expiresIn.timeRemaining + " " + p.expiresIn.value}</b>
                                        )}
                                        {p.data.status === "ACTIVE" && (
                                            "Expires in " + p.expiresIn.timeRemaining + " " + p.expiresIn.value
                                        )}
                                        {p.data.status === "PENDING" && (
                                            "Pending"
                                        )}
                                        {p.data.status === "EXPIRED" && (
                                            "Expired"
                                        )}
                                    </td>
                                    <td>
                                        <>
                                            <Button disabled={isLoading} onClick={() => {
                                                window.location.href = "/listing/edit/" + p.data.id;
                                            }} variant="link">
                                                <FontAwesomeIcon title={"edit"} style={{color: '#3eb5e5', cursor: "pointer"}} icon={faPencil}/>
                                            </Button>
                                        </>
                                        {(p.data.status === "EXPIRING_SOON" || p.data.status === "EXPIRED") && (
                                            <>
                                                <Button disabled={isLoading} onClick={() => {
                                                    doRenewListing(p.data.id)
                                                }} variant="link">
                                                    <FontAwesomeIcon style={{color: '#3eb5e5'}} icon={faRotateRight}/>
                                                </Button>
                                            </>
                                        )}
                                        <>
                                            <Button disabled={isLoading} onClick={() => {
                                                doDeleteListing(p.data.id)
                                            }} variant="link">
                                                <FontAwesomeIcon style={{color: '#3eb5e5', cursor: "pointer"}} icon={faTrash}/>
                                            </Button>
                                        </>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Col>
                )}
            </Row>
        </Container>
    );
}

export default MyListings;
