import React, {useEffect, useState,} from 'react';
import {Col, Container, Form, Pagination, Row, Spinner} from "react-bootstrap";
import {getAllListings} from '../utils/api';
import {LazyLoadImage} from "react-lazy-load-image-component";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import HelpIDMap from "./HelpIDMap";
import {getCenterForMap} from "../utils/helpers";
import UserDisplay from "../components/UserDisplay";

alertify.set('notifier', 'position', 'top-right');


export function loading(width, height) {
    return (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"
                     style={{width: width + "px", height: height + "px", marginTop: "10vh"}}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

const Main = (props) => {
    const [sort, setSort] = useState(() => "Most Recent");
    const [markers, setMarkers] = useState(() => null);
    const [bounds, setBounds] = useState(() => []);
    const [markerHighlightId, setMarkerHighlightId] = useState(() => null);
    const [listings, setListings] = useState(() => loading(100, 100));
    const [pageInfo, setPageInfo] = useState(() => null);
    const [initialMapCenter, setInitialMapCenter] = useState();
    const [limitSearchToMap, setLimitSearchToMap] = useState(false);

    function singleListing(id) {
        window.location.replace(`/listing/view/${id}`);
    }

    function hoverListing(listingId) {
        setMarkerHighlightId(listingId)
    }

    function stopHoverListing() {
        setMarkerHighlightId(null)
    }

    useEffect(() => {
        if (limitSearchToMap) {
            load();
        }
    }, [bounds])

    useEffect(() => {
        load();
    }, [limitSearchToMap])

    async function load(pageNum, sortBy) {
        let boundsToUse;
        if (limitSearchToMap && bounds && bounds.length === 4) {
            boundsToUse = bounds
        }
        const pageVal = pageNum ? pageNum : pageInfo ? pageInfo.page : 1
        const sortVal = sortBy ? sortBy : sort
        setListings(loading(100, 100));
        const response = await getAllListings(boundsToUse, pageVal, sortVal);
        if (!response.ok) {
            alertify.error("Error loading listings. Please revisit at a later time.", 0)

        } else {
            if (response.status === 204) {
                setListings((
                    <div className="d-flex justify-content-center align-items-center" key="None Found"
                         style={{minHeight: "60vh"}}>
                        <Container fluid>
                            <Row>
                                <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" fill="currentColor"
                                     viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path
                                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
                                </svg>
                            </Row>
                            <Row>
                                <p className="text-center fs-1 mt-4">No Listings Found!</p>
                            </Row>
                        </Container>

                    </div>))
                setPageInfo(null)
                setMarkers([])
                return;
            }
            const listingData = await response.json();
            setListings(listingData.listings.map(listing => {
                return (
                    <Col xl={4} lg={6} xs={12} className="p-2" key={listing.data._id}>
                        <div id={"listing" + listing.data._id} className="postCards"
                             onClick={() => singleListing(listing.data._id)}
                             onMouseOver={() => {
                                 hoverListing(listing.data._id)
                             }} onMouseOut={stopHoverListing}>
                            <Row className="d-flex justify-content-center postCardsImageDiv">
                                <LazyLoadImage
                                    src={`${process.env.REACT_APP_CDN}${listing.data.images}`}
                                    className="postCardsImage"
                                />
                            </Row>
                            <Row className="d-flex justify-content-bottom text-center mx-2 pt-2"
                                 style={{backgroundColor: "var(--main-color)"}}>
                                <div><p className="text-center fs-5 px-2">{listing.data.title}</p></div>
                                <div><p> Date of Incident: {listing.data.formattedDate}</p></div>
                                <div><p> Category: {listing.data.categoryId.category} </p></div>
                                {listing.data.subCategory ? (<p> Type: {listing.data.subCategory} </p>) : null}
                            </Row>
                            <Row className=""
                                 style={{position: "absolute", bottom: '0px', center: "0px", width: "100%"}}>
                                <Col xs={12}>
                                    <p className="text-center ps-2"> Posted by: <UserDisplay
                                        user={listing.data.userId}/></p>
                                </Col>
                                {/*<Col xs={12}>*/}
                                {/*    <p className="text-center ps-2"> Views: {listing.data.views}</p>*/}
                                {/*</Col>*/}
                            </Row>
                        </div>
                    </Col>
                )
            }));
            setPageInfo(listingData.pageData);
            setMarkers(listingData.markers);
        }
    }

    function paginate() {
        if (pageInfo) {
            let pageItems = []
            if (pageInfo.totalPages <= 3) {
                for (let i = 0; i < pageInfo.totalPages; i++) {
                    pageItems.push(<Pagination.Item active={pageInfo.page === (i + 1)}
                                                    onClick={() => pageInfo.page === (i + 1) ? null : load(i + 1)}>{i + 1}</Pagination.Item>)
                }
                return (<>
                    <Pagination.Prev onClick={() => load(pageInfo.page - 1)}
                                     disabled={pageInfo ? pageInfo.hasPrevPage ? false : true : true}/>
                    {pageItems}
                    <Pagination.Next onClick={() => load(pageInfo.page + 1)}
                                     disabled={pageInfo ? pageInfo.hasNextPage ? false : true : true}/>
                </>)
            } else {
                return (<>
                    <Pagination.First onClick={() => load(1)}
                                      disabled={pageInfo ? pageInfo.hasPrevPage ? false : true : true}/>
                    <Pagination.Prev onClick={() => load(pageInfo.page - 1)}
                                     disabled={pageInfo ? pageInfo.hasPrevPage ? false : true : true}/>
                    <Pagination.Item active={pageInfo.page === 1}
                                     onClick={() => pageInfo.page === 1 ? null : pageInfo.page === pageInfo.totalPages ?
                                         load(pageInfo.page - 2) :
                                         load(pageInfo.page - 1)}>
                        {pageInfo.page === 1 ? 1 : pageInfo.page === pageInfo.totalPages ? pageInfo.totalPages - 2 : pageInfo.page - 1}
                    </Pagination.Item>
                    <Pagination.Item active={pageInfo.page != pageInfo.totalPages && pageInfo.page !== 1}
                                     onClick={() => pageInfo.page === 1 ? load(2) :
                                         pageInfo.page === pageInfo.totalPages ? load(pageInfo.totalPages - 1) : null}>
                        {pageInfo.page === 1 ? 2 : pageInfo.page === pageInfo.totalPages ? pageInfo.totalPages - 1 : pageInfo.page}
                    </Pagination.Item>
                    <Pagination.Item active={pageInfo.page === pageInfo.totalPages}
                                     onClick={() => pageInfo.page === pageInfo.totalPages ? null : pageInfo.page === 1 ?
                                         load(pageInfo.page + 2) :
                                         load(pageInfo.page + 1)}>
                        {pageInfo.page === 1 ? 3 : pageInfo.page === pageInfo.totalPages ? pageInfo.totalPages : pageInfo.page + 1}
                    </Pagination.Item>
                    <Pagination.Next onClick={() => load(pageInfo.page + 1)}
                                     disabled={pageInfo ? pageInfo.hasNextPage ? false : true : true}/>
                    <Pagination.Last disabled={pageInfo ? pageInfo.hasNextPage ? false : true : true}
                                     onClick={() => load(pageInfo.totalPages)}/>
                </>)
            }
        }
    }

    useEffect(() => {
        getCenterForMap().then(centerVals => {
            if (centerVals && centerVals.length === 2) {
                const initCenter = {"lat": Number(centerVals[1]), "lng": Number(centerVals[0])}
                setInitialMapCenter(initCenter)
            }
        })
    }, [])

    useEffect(() => {

    }, [listings])

    let mapZoom = 12
    if (window.localStorage && window.localStorage.lastZoom) {
        try {
            mapZoom = Number(window.localStorage.lastZoom)
        } catch {
        }
        if (!mapZoom || isNaN(mapZoom)) {
            mapZoom = 12;
        }
    }
    return (
        <Container fluid className="" style={{minHeight: "80vh"}}>
            <Row>
                <Col xxl={2} xs={12}>
                    <script async
                            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3671917448857789"
                            crossOrigin="anonymous"></script>
                </Col>
                <Col>
                    <Row className="d-flex justify-content-center align-items-center mt-3 mapRow"
                         style={{height: "50vh"}}>
                        {initialMapCenter && (
                            <HelpIDMap id={"main"} markers={markers} zoom={mapZoom} center={initialMapCenter}
                                       highlightMarkerId={markerHighlightId} onIdle={(map) => {
                                console.log("Map idle main")
                                if (map && map.getCenter()) {
                                    const newLat = map.getCenter().lat()
                                    const newLng = map.getCenter().lng()
                                    if (window.localStorage) {
                                        try {
                                            window.localStorage.lastCenterLat = Number(newLat);
                                            window.localStorage.lastCenterLng = Number(newLng);
                                            window.localStorage.lastZoom = Number(map.getZoom())
                                        } catch {
                                        }
                                    }
                                }
                                if (map && map.getBounds()) {
                                    const b = map.getBounds()
                                    const ne = b.getNorthEast()
                                    const sw = b.getSouthWest()
                                    const boundsList = [ne.lat(), ne.lng(), sw.lat(), sw.lng()]
                                    setBounds(boundsList)
                                }
                            }}/>
                        )}
                    </Row>
                    <Row className="mt-4 sortRow">
                        <Col md={5} xs={12} className='d-flex pb-2'>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label="Limit to Map"
                                    value={limitSearchToMap}
                                    onChange={(e) => {
                                        setLimitSearchToMap(e.target.checked)
                                    }}
                                />
                            </Form>
                        </Col>
                        <Col md={{span: 5, offset: 2}} xs={12} className='d-flex justify-content-end pb-2'>
                            <p className="my-auto pe-2">Sort by:</p>
                            <select className="p-1" onChange={(e) => {
                                setSort(e.target.value);
                                load(undefined, e.target.value);
                            }} value={sort}>
                                <option value="Most Recent">Date Listed (newest to oldest)</option>
                                <option value="Most Recent Inv">Date Listed (oldest to newest)</option>
                                <option value="Date of Incident">Date of Incident (newest to oldest)</option>
                                <option value="Date of Incident Inv">Date of Incident (oldest to newest)</option>
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xxl={12}>
                            <Row style={{minHeight: "60vh"}}>
                                {listings}
                            </Row>
                            <Row className="py-2" style={{borderTop: "2px solid var(--cyan)"}}>
                                <Pagination className="d-flex justify-content-center m-0 p-0">
                                    {paginate()}
                                </Pagination>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* page selector */}
                    </Row>
                    {/* main */}
                </Col>
                <Col xxl={2} xs={12}>
                    {/*Ad Space*/}
                </Col>
            </Row>

        </Container>
    );
}

export default Main;
