import React, {useEffect, useState,} from 'react';
import {Col, Container, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {signup} from '../utils/api';
import {checkPasswords, createPhoneNumber} from '../utils/helpers';
import InputPassword from "../components/InputPassword";
import alertify from "alertifyjs";
import 'alertifyjs/build/css/alertify.css';
import Alert from "react-bootstrap/Alert";

alertify.set('notifier', 'position', 'top-right');


function Signup(props) {

    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [personalWebsite, setPersonalWebsite] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [createSuccess, setCreateSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    async function attemptSignup() {
        setLoading(true);
        try {

            setErrorMessage("");
            if(!firstName || !lastName || !email) {
                return setErrorMessage("Please fill out form");
            }
            const passwordResult = checkPasswords(password, confirmPassword);
            if (passwordResult !== true) {
                return setErrorMessage(passwordResult);
            }
            const response = await signup(personalWebsite, firstName, lastName, phoneNumber, email, password);
            if (response.ok) {
                setCreateSuccess(true);
            } else if (response.status === 400) {
                alertify.error("Unable to create your account. Please try again.");
                setErrorMessage("Please confirm fields are correct and try again.");
            } else if (response.status === 500) {
                alertify.error("Unknown error creating your account. Please try again at a later time");
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (props.loggedIn) {
            window.location = "/"
        }
    }, [props.loggedIn]);
    return (
        <Container fluid="xxl">
            {createSuccess && (
                <Row className="d-flex justify-content-center align-items-center mt-5">
                    <Alert variant="info" style={{textAlign: "center"}}>
                        Success! Your account has been created. An email has been sent for confirmation.
                    </Alert>
                </Row>
            )}
            {!createSuccess && (
                <>
                    <Row className="d-flex justify-content-center align-items-center mt-5">
                        <Col xl={4} md={6} sm={10} xs={11} className="loginDiv">
                            <h2 className="text-center mt-2">Create Account</h2>
                            <div className="my-3" style={{display: "none"}}>
                                <label htmlFor="personalWebsite">Personal Website:</label>
                                <input type="text" id="personalWebsite" className=""
                                       maxLength={40}
                                       onChange={(e) => setPersonalWebsite(e.target.value)}></input>
                            </div>
                            <div className="my-3">
                                <label htmlFor="email">Email:</label>
                                <input disabled={loading} type="email" id="email" className="" minLength={10}
                                       maxLength={40}
                                       required onChange={(e) => setEmail(e.target.value.trim())}></input>
                            </div>

                            <div className="my-3">
                                <label htmlFor="firstName">First Name:</label>
                                <input disabled={loading} type="text" id="firstName" className="" maxLength={40}
                                       required onChange={(e) => setFirstName(e.target.value.trim())}></input>
                            </div>
                            <div className="my-3">
                                <label htmlFor="lastName">Last Name:</label>
                                <input disabled={loading} type="text" id="lastName" className="" maxLength={40}
                                       required onChange={(e) => setLastName(e.target.value.trim())}></input>
                            </div>
                            <div className="my-3">
                                <label htmlFor="phoneNumber">Phone Number:</label> <OverlayTrigger
                                key="depart"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-depart`}>
                                        Your phone number will not be shared with anyone unless you choose to share it.
                                    </Tooltip>
                                }
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="me-2" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path
                                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                </svg>
                            </OverlayTrigger>
                                <input disabled={loading} type="tel" id="phoneNumber" className="" value={phoneNumber}
                                       minLength={12} maxLength={12}
                                       onChange={(e) => setPhoneNumber(createPhoneNumber(e.target.value, phoneNumber))}></input>
                            </div>
                            <div className="my-3">
                                <label htmlFor="password">Password:</label>
                                <InputPassword disabled={loading} id="password"
                                               onChange={(e) => setPassword(e.target.value)} password={password}/>
                            </div>
                            <div className="my-3">
                                <label htmlFor="passwordConfirm">Confirm Password:</label>
                                <InputPassword disabled={loading} id="passwordConfirm"
                                               onChange={(e) => setConfirmPassword(e.target.value)}
                                               password={confirmPassword}/>
                            </div>
                            <p className="text-center text-danger">{errorMessage}</p>
                            <div className="d-flex justify-content-center align-items-center my-3">
                                <button disabled={loading} type="button" onClick={attemptSignup} className="button">Sign
                                    Up
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5 d-flex justify-content-center align-items-center">
                        <p className="text-center">Have an account? <a href="/login" className="text-link">Login</a></p>
                    </Row>
                </>
            )}
        </Container>
    );
}

export default Signup;
