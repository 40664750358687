import React, {useEffect} from 'react';
import {Container, Row} from "react-bootstrap";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import ListingForm from "../components/Listing/ListingForm";
import {createListing, getOneListing, updateListing} from "../utils/api";
import Col from "react-bootstrap/Col";
import {useParams} from "react-router-dom";

alertify.set('notifier', 'position', 'top-right');

function PostListing(props) {
    const params = useParams();
    const isEdit = props.isEdit;
    const listingId = params.listingId;
    if (isEdit && !listingId) {
        window.location = "/notFound";
    }
    const [loading, setLoading] = React.useState(false)
    const [errorMessages, setErrorMessages] = React.useState([])
    const [listingData, setListingData] = React.useState({})


    useEffect(() => {
        if (!isEdit) {
            return;
        }
        getOneListing(listingId).then(response => {
            if (response.ok && response.status === 200) {
                response.json().then(listingData => {
                    setListingData(listingData.data);
                });
            } else {
                alertify.error("We were unable to load this listing.", "2");
            }
        }).catch(() => {
            alertify.error("We were unable to load this listing.", "2");
        });
    }, []);

    const handlePostListing = async (formData) => {
        const errorVerb = isEdit ? "updating" : "creating";
        try {
            const errors = [];
            setLoading(true);
            if (!formData.get('title') || !formData.get("title").trim()) {
                errors.push("Enter a title.");
            }
            if (!formData.get('date')) {
                errors.push("Select date of incident.");
            }
            if (!formData.get('categoryId') || formData.get("categoryId").includes("Select Category")) {
                errors.push("Select a category.");
            }
            const hasCurrentLocation = (isEdit && listingData && listingData.location);
            if (!hasCurrentLocation) {
                if (!formData.get('mapLat') || !formData.get("mapLng")) {
                    errors.push("Select location of incident on map.");
                }
            }
            const filesInput = document.getElementById("listingFiles");
            const files = filesInput.files;
            const filesToKeep = new Set();
            if (isEdit && listingData) {
                if(listingData.images) {
                    for (let i = 0; i < listingData.images.length; i++) {
                        filesToKeep.add(listingData.images[i]);
                    }
                }
                if(listingData.video) {
                    for (let i = 0; i < listingData.video.length; i++) {
                        filesToKeep.add(listingData.video[i]);
                    }
                }
            }
            if (formData.get("removeImages")) {
                const removeImagesArr = JSON.parse(formData.get("removeImages"));
                for (let i = 0; i < removeImagesArr.length; i++) {
                    filesToKeep.delete(removeImagesArr[i]);
                }
            }
            const numCurrentFiles = filesToKeep.size;
            let numNewFiles = 0;
            if (files) {
                // Loop through files
                for (let i = 0; i < files.length; i++) {
                    let file = files.item(i);
                    if (file.size > 0) {
                        numNewFiles++;
                    }
                }
            }
            if ((numNewFiles + numCurrentFiles) > 5) {
                errors.push("Max of 5 files can be uploaded");
            }
            if ((numNewFiles + numCurrentFiles) === 0) {
                errors.push("Upload at least one image or video.");
            }
            setErrorMessages(errors);
            if (errors && errors.length > 0) {
                return;
            }
            let response;
            if (isEdit) {
                response = await updateListing(listingId, formData);
            } else {
                response = await createListing(formData);
            }
            if (response.ok) {
                const data = await response.json();
                window.location = "/listing/view/" + data.data._id;
            } else if (response.status === 400) {
                await response.json();
                alertify.error(`Oops! There was an error ${errorVerb} your listing.`);
                setErrorMessages(["Please confirm fields are correct and image sizes are valid."]);
            } else {
                alertify.error(`Oops! There was an error ${errorVerb} your listing. Please try again at a later time.`);
            }
        } catch (e) {
            console.log(e);
            alertify.error(`Oops! There was an error ${errorVerb} your listing. Please try again at a later time.`);
        } finally {
            setLoading(false);
        }

    }
    return (
        <Container>
            <Row>
                <Col md={{span: 8, offset: 2}} xs={12}>
                    <h2 style={{textAlign: "center", marginTop: '20px'}}>
                        {isEdit ? "Edit Listing" : "Create Listing"}
                    </h2>
                </Col>
            </Row>
            <Row style={{marginBottom: "50px"}}>
                <Col md={{span: 8, offset: 2}} xs={12}>
                    <ListingForm isEdit={isEdit} listingData={listingData} onSubmit={handlePostListing}
                                 loading={loading}
                                 errorMessages={errorMessages}/>
                </Col>
            </Row>
        </Container>
    );
}

PostListing.propTypes = {};
PostListing.defaultProps = {};

export default PostListing;
