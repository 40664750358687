import React from 'react';
import AdminUsers from "./AdminUsers";
import {Container} from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AdminListings from "./AdminListings";
import AdminTips from "./AdminTips";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminCategories from "./AdminCategories";

const Admin = (props) => {

    let eventKey = "users";
    if (localStorage && localStorage.getItem("adminPage")) {
        eventKey = localStorage.getItem("adminPage");
    }
    return (
        <Container fluid={true}>
            <h1 style={{textAlign: "center", marginTop: "25px"}}>Admin</h1>
            <Row className="justify-content-md-center">
                <Col md={10}>
                    <Tabs
                        defaultActiveKey={eventKey}
                        transition={false}
                        onSelect={(k) => localStorage.setItem("adminPage", k)}
                    >
                        <Tab eventKey="users" title="Users">
                            <AdminUsers/>
                        </Tab>
                        <Tab eventKey="categories" title="Categories">
                            <AdminCategories/>
                        </Tab>
                        <Tab eventKey="listings" title="Listings">
                            <AdminListings isSysAdmin={props.isSysAdmin}/>
                        </Tab>
                        <Tab eventKey="tips" title="Tips">
                            <AdminTips/>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    )
}

export default Admin;
