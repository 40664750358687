import React from 'react';

function SelectSubCategory({subCategories, subCateId, onChange, ...rest}) {
    return <>
        {
            subCategories && <select
                value={subCateId}
                className="p-1"
                onChange={(e) => onChange(e.currentTarget.value)}
                style={{maxWidth: "100%"}}
                {...rest}
            >
                <option value="">All</option>
                {subCategories.map(category => (
                    <option key={category} value={category}>{category}</option>
                ))}
            </select>
        }
    </>
}

SelectSubCategory.propTypes = {};
SelectSubCategory.defaultProps = {};

export default SelectSubCategory;
