import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserShield} from "@fortawesome/free-solid-svg-icons";
import React from "react";


const VerifiedUserShield = (props) => {
    return (<>
            <FontAwesomeIcon style={{color: '#3eb5e5'}} icon={faUserShield}/>&nbsp;{props.name}
        </>
    )
}

export default VerifiedUserShield;
