import React, {Component, useEffect, useRef, useState} from "react";
import {Status, Wrapper} from "@googlemaps/react-wrapper";
import markerImage from "../assets/images/MapPin.png";
import ReactDOMServer from 'react-dom/server';
import {Spinner} from "react-bootstrap";
import {addressGeoCode} from "../utils/api";

const render = (status) => {
    if (status === Status.SUCCESS) {
        return <HelpIDMap/>;
    }
    return <Spinner animation="border" role="status" style={{width: "100px", height: "100px"}}/>
};

const updatePinLocation = (map, latLng) => {
    if (!map || !latLng) {
        return
    }
    if (map.lastCircle) {
        map.lastCircle.setCenter(latLng);
    }
    if (map.lastMarker) {
        map.lastMarker.setPosition(latLng);
    }
}

const onCenterChangedOuter = (map) => {
    // console.log("center changed" + map);
    // m.centerCircle.setCenter(m.getCenter())
    // setZoom(m.getZoom());
    // setCenter(m.getCenter().toJSON());
};

const onIdleOuter = (map) => {
    // console.log("on idle " + map);
    // console.log(m);
    // m.centerCircle.setCenter(m.getCenter())
    // setZoom(m.getZoom());
    // setCenter(m.getCenter().toJSON());
};

const onCircleMarkerDragend = (e, map) => {
    updatePinLocation(map, e.latLng)
};

const onClickOuter = (e, map) => {
    updatePinLocation(map, e.latLng)
    // setZoom(m.getZoom());
    // setCenter(m.getCenter().toJSON());
};


const ListingInfoWindow = (options) => {
    const [infoWindow, setInfoWindow] = useState();

    useEffect(() => {
        if (!infoWindow) {
            setInfoWindow(new google.maps.InfoWindow());
        }

        // remove infoWindow from map on unmount
        return () => {
            if (infoWindow) {
                infoWindow.setMap(null);
            }
        };
    }, [infoWindow]);

    useEffect(() => {
        if (infoWindow) {
            infoWindow.addListener("closeclick", (e) => {
                options.closeClick(e, infoWindow)
            })
            infoWindow.setOptions(options);
            infoWindow.setContent(ReactDOMServer.renderToStaticMarkup(<div>
                    <h4>{options.title}</h4>
                    <p>{options.summary}</p>
                    <a href={`/listing/view/${options.listingId}`}>See Listing</a>
                </div>)
            )
        }
    }, [infoWindow, options]);
    return null;
};


const ListingMarker = (options) => {
    const [marker, setMarker] = useState();

    useEffect(() => {
        if (!marker) {
            setMarker(new google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            marker.addListener("dragend", (e) => {
                options.onMarkerDragend(e, marker)
            });
            marker.addListener("mouseover", (e) => {
                options.onMarkerMouseOver(e, marker)
            });
            marker.addListener("mouseout", (e) => {
                options.onMarkerMouseOut(e, marker)
            });
            marker.addListener("click", (e) => {
                options.onClick(e, marker)
            })
            if (marker.map) {
                marker.map.lastMarker = marker;
            }
        }
    }, [marker, options]);
    return null;
};


const CenterCircle = (options) => {
    const [circle, setCircle] = useState();

    useEffect(() => {
        if (!circle) {
            setCircle(new google.maps.Circle());
        }

        // remove circle from map on unmount
        return () => {
            if (circle) {
                circle.setMap(null);
            }
        };
    }, [circle]);

    useEffect(() => {
        if (circle) {
            // console.log(circle)
            circle.setOptions(options);
            if (circle.map) {
                circle.map.lastCircle = circle;
            }
        }
    }, [circle, options]);
    return null;
};

function MyMapComponent({
                            id,
                            children,
                            style,
                            onCenterChanged,
                            onIdle,
                            onClick,
                            addressSearchCallback,
                            showAddressSearchInput,
                            addressSearchZoom,
                            ...options
                        }
                            : {
    center: google.maps.LatLngLiteral;
    zoom: number;
}) {
    // console.log("Map Options")
    // console.log(options)

    const ref = useRef(null);
    const [map, setMap] = useState();
    const [isBusy, setIsBusy] = useState(false);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, options));
        }
    }, [ref, map]);

    useEffect(() => {
        if (map) {
            map.addListener("center_changed", () => onCenterChanged(map));
            map.addListener("idle", () => onIdle(map));
            map.addListener("click", (e) => {
                onClick(e, map)
            });
        }
    }, [map]);

    const inputValueKeyUp = (e) => {
        if (!showAddressSearchInput) {
            return;
        }
        if (e.key !== "Enter") {
            return;
        }
        if (isBusy) {
            return;
        }
        let searchAddr = e.target.value;
        if (searchAddr) {
            searchAddr = searchAddr.trim();
        }
        if (searchAddr.length < 2) {
            return;
        }

        // Cancel the default action, if needed
        e.preventDefault();

        // Trigger the button element with a click
        setIsBusy(true)

        addressGeoCode(searchAddr).then(response => {
            if (response.ok) {
                response.json().then(addressData => {
                    const newCenter = {"lat": addressData[1], "lng": addressData[0]};
                    map.setCenter(newCenter);
                    if (addressSearchZoom) {
                        map.setZoom(addressSearchZoom)
                    }
                    if (addressSearchCallback) {
                        addressSearchCallback(map, newCenter)
                    }
                })
            } else {
                console.log("Fail geocode")
            }

        }).finally(() => {
            setTimeout(() => {
                    setIsBusy(false);
                }, 2000
            )
        });
    }

    return (
        <>
            {showAddressSearchInput && (
                <div id={"mapZipSearchDiv" + id} className={"mapZipSearchDiv"}>
                    <input disabled={isBusy} className="mapZipSearch" id={"mapZipSearch" + id} type="text"
                           onKeyUp={inputValueKeyUp} placeholder={"Search Address"}/>
                </div>
            )}
            <div ref={ref} id={"map" + id} style={style}/>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, {map});
                }
            })}
        </>
    );
}

export class TheMarkers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeMarkerData: null,
            hoverMarkerId: null,
        }
    }

    render() {
        if (!this.props.markers) {
            return null;
        }
        const activeMarkerData = this.state.activeMarkerData;
        const sId = this.props.highlightMarkerId
        return <>
            {this.props.markers.map((markerData) => (
                <ListingMarker
                    markerId={markerData._id}
                    map={this.props.map}
                    title={markerData.title}
                    key={markerData._id}
                    icon={{
                        url: markerImage,
                        scaledSize: (markerData._id == sId) ? {width: 40, height: 60} : {width: 30, height: 50},
                    }}
                    position={{lat: markerData.lat, lng: markerData.lon}}
                    clickable={true}
                    onClick={(e, markerMapObj) => {
                        this.setState({activeMarkerData: markerData})
                    }}
                    onMarkerMouseOut={(e, markerData) => {
                        const ele = document.getElementById("listing" + markerData.markerId)
                        if (ele) {
                            ele.classList.remove("postCardsMarker");
                        }
                        markerData.setIcon({
                            url: markerImage,
                            scaledSize: {width: 30, height: 50},
                        })
                    }}
                    onMarkerMouseOver={(e, markerData) => {
                        const ele = document.getElementById("listing" + markerData.markerId)
                        if (ele) {
                            ele.classList.add("postCardsMarker");
                        }
                        markerData.setIcon({
                            url: markerImage,
                            scaledSize: {width: 40, height: 60},
                        })
                    }}
                >
                </ListingMarker>
            ))}

            {activeMarkerData && (<ListingInfoWindow position={{lat: activeMarkerData.lat, lng: activeMarkerData.lon}}
                                                  map={this.props.map}
                                                  title={activeMarkerData.title}
                                                  summary={activeMarkerData.summary}
                                                  listingId={activeMarkerData._id}
                                                  closeClick={() => {
                                                      this.setState({activeMarkerData: null})
                                                  }}
            >
            </ListingInfoWindow>)}
        </>
    }


}


export default function HelpIDMap({
                                      id,
                                      center = undefined,
                                      centerMarkerCircleLocation = undefined,
                                      zoom = 14,
                                      onCenterChanged = () => {
                                      },
                                      onIdle = () => {
                                      },
                                      onClick = () => {
                                      },
                                      addressSearchCallback = () => {
                                      },
                                      showAddressSearchInput = true,
                                      addressSearchZoom = 14,
                                      markers = [],
                                      createCenterMarker = false,
                                      createCenterMarkerCircle = false,
                                      onMarkerDragend = () => {
                                      },
                                      isStatic = false,
                                      highlightMarkerId = null,
                                      ...options
                                  }) {
    if (!center) {
        throw "No Map center"
    }
    if (!centerMarkerCircleLocation && (createCenterMarker || createCenterMarkerCircle)) {
        centerMarkerCircleLocation = center;
    }

    const createMarkers = (!createCenterMarkerCircle && !createCenterMarker)
    return (
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY} render={render}>
            <MyMapComponent
                id={id}
                center={center}
                onCenterChanged={onCenterChanged}
                onClick={onClick}
                onIdle={onIdle}
                showAddressSearchInput={showAddressSearchInput && !isStatic}
                style={{flexGrow: "1", height: "100%", minHeight: "350px"}}
                addressSearchCallback={addressSearchCallback}
                addressSearchZoom={addressSearchZoom}
                zoom={zoom}
                streetViewControl={false}
                mapTypeControl={false}
                scaleControl={false}
                zoomControl={true}
                fullscreenControl={false}
                draggable={!isStatic}
                keyboardShortcuts={!isStatic}
                disableDefaultUI={isStatic}
                highlightMarkerId={highlightMarkerId}
                gestureHandling={"greedy"}
                options={options}
            >
                {createMarkers && (
                    <TheMarkers markers={markers} highlightMarkerId={highlightMarkerId}/>
                )}
                {!!createCenterMarker && (
                    <ListingMarker icon={{
                        url: markerImage,
                        scaledSize: {width: 30, height: 50}
                    }}
                                   draggable={!isStatic}
                                   clickable={!isStatic}
                                   position={centerMarkerCircleLocation}
                                   center={centerMarkerCircleLocation}
                                   onMarkerDragend={onMarkerDragend}
                                   onMarkerMouseOut={() => {
                                   }}
                                   onMarkerMouseOver={() => {
                                   }}
                    />
                )}
                {!!createCenterMarkerCircle && (
                    <CenterCircle draggable={false} clickable={false} radius={125} center={centerMarkerCircleLocation}
                                  fillColor={"cyan"}
                                  strokeColor={"lightblue"}/>
                )}
            </MyMapComponent>
        </Wrapper>
    );
}
