import React, {useEffect, useState,} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {login} from '../utils/api';
import InputPassword from "../components/InputPassword";
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css'
import Alert from "react-bootstrap/Alert";

alertify.set('notifier', 'position', 'top-right');


function Login(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [needsActivation, setNeedsActivation] = useState(false);

    async function attemptLogin(e) {
        e.preventDefault();
        setErrorMessage("");
        const response = await login(email, password);
        if (response.status === 204) {
            setNeedsActivation(true);
            return;
        } else {
            setNeedsActivation(false);
        }
        if (response.ok) {
            if (props.nextUrl) {
                window.location = props.nextUrl;
            } else {
                window.location = "/";
            }
        } else if (response.status === 400) {
            await response.json();
            alertify.error("Unable to login");
            setErrorMessage("Please check email and password, and the try again");
        } else if (response.status === 403) {
            const banReason = await response.json();
            setErrorMessage(banReason.message + " Ban Reason: " + banReason.banReason);
            window.location = "/"
        } else {
            alertify.error("Unknown error logging in. Please try again at a later time");
            setErrorMessage("Please confirm fields are correct and try again.");
        }
    }

    useEffect(() => {
        if (props.loggedIn) {
            window.location = "/";
        }
    }, []);
    return (
        <Container fluid>
            {props.activated && (
                <Row>
                    <Alert variant="info" style={{textAlign: "center"}}>
                        Success! Your account has been activated. You may now login.
                    </Alert>
                </Row>
            )}
            {needsActivation && (
                <Row>
                    <Alert variant="info" style={{textAlign: "center"}}>
                        In order to login, you need to confirm your email. We have resent the confirmation email to your
                        email.
                    </Alert>
                </Row>
            )}
            <Row className="d-flex justify-content-center align-items-center mt-5">
                <Col lg={3} md={6} xs={12} className="loginDiv">
                    <h2 className="text-center mt-2">Login</h2>
                    <form onSubmit={(e) => attemptLogin(e)}>
                        <div className="my-3">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                   className="" maxLength={40} required></input>
                        </div>
                        <div className="my-3">
                            <label htmlFor="password">Password:</label>
                            <InputPassword onChange={(e) => setPassword(e.target.value)} password={password}/>
                        </div>
                        <p className="text-danger text-center">{errorMessage}</p>
                        <div className="d-flex justify-content-center align-items-center my-3">
                            <button type="submit" className="button">Submit</button>
                        </div>
                    </form>
                </Col>
            </Row>
            <Row className="mt-5 d-flex justify-content-center align-items-center">
                <p className="text-center">
                    Forgot Your Password?&nbsp;
                    <a href="/resetPassword" className="text-link">Click Here</a>
                </p>
                <p className="text-center">
                    Don't have an account?&nbsp;
                    <a href="/signup" className="text-link">Create Account</a>
                </p>
            </Row>
        </Container>
    );
}

export default Login;
