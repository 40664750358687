import React, {useEffect, useState,} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {setUserNewPassword} from '../utils/api';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css'
import Alert from "react-bootstrap/Alert";
import queryString from "query-string";
import {checkPasswords} from "../utils/helpers";
import InputPassword from "../components/InputPassword";

alertify.set('notifier', 'position', 'top-right');


function SetNewUserPassword(props) {
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [resetId, setResetId] = useState("");
    const [hasFinished, setHasFinished] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function doSetNew() {
        const passwordResult = checkPasswords(password1, password2);
        if (passwordResult !== true) {
            return setErrorMessage(passwordResult);
        }
        setErrorMessage(null);
        setUserNewPassword(password1, resetId).then((response) => {
            if (response.status !== 200) {
                updateResult(false);
            } else {
                updateResult(true);
            }
        }).catch(() => {
            updateResult(false);
        }).finally(() => {
            setHasFinished(true);
        });
    }

    function updateResult(result) {
        setUpdateSuccess(result);
        try {
            window.localStorage.setItem("r" + resetId, result ? "SUCCESS" : "ERROR");
        } catch (e) {
        }

    }

    useEffect(() => {
        const resetId = queryString.parse(window.location.search).rId;
        if (!resetId || resetId.trim() === "") {
            window.location = "/";
            return;
        }
        if (window.localStorage) {
            const pastResult = window.localStorage.getItem("r" + resetId);
            if (pastResult === "SUCCESS" || pastResult === "ERROR") {
                setHasFinished(true);
                setUpdateSuccess(pastResult === "SUCCESS");
            }
        }
        setResetId(resetId);
    }, []);

    useEffect(() => {
        if (!password1 || !password1.trim()) {
            return;
        }
        const passwordResult = checkPasswords(password1, password2);
        if (passwordResult === true) {
            return setErrorMessage(null);
        } else {
            return setErrorMessage(passwordResult);
        }
    }, [password1, password2]);

    return (
        <Container fluid>
            {hasFinished && (
                <Row className="d-flex justify-content-center align-items-center mt-5">
                    {updateSuccess && (
                        <Alert variant="info" style={{textAlign: "center"}}>
                            You password has been updated. You can now <a href={"/login"}>login</a> with your new
                            password.
                        </Alert>
                    )}
                    {!updateSuccess && (
                        <Alert variant="danger" style={{textAlign: "center"}}>
                            We are sorry - there was an error updating your password. Please try again at a later
                            time.
                        </Alert>
                    )}
                </Row>
            )}
            {!hasFinished && (
                <Row className="d-flex justify-content-center align-items-center mt-5">
                    <Col lg={3} md={6} xs={12} className="loginDiv">
                        <h2 className="text-center my-4">Reset Password</h2>
                        <div className="my-3">
                            <label htmlFor="password">Password:</label>
                            <InputPassword onChange={(e) => setPassword1(e.target.value.trim())}
                                           password={password1}/>
                        </div>
                        <div className="my-3">
                            <label htmlFor="passwordConfirm">Confirm Password:</label>
                            <InputPassword id="passwordConfirm"
                                           onChange={(e) => setPassword2(e.target.value.trim())}
                                           password={password2}/>
                        </div>
                        {errorMessage && (
                            <p className="text-center text-danger">{errorMessage}</p>
                        )}
                        <div className="d-flex justify-content-center align-items-center my-4">
                            <button type="submit" onClick={doSetNew} className="button">Update Password</button>
                        </div>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default SetNewUserPassword;
